import { Button, DialogActions, DialogTitle, Typography } from '@mui/material';
import { User } from '../../../../types/types';
import QRModalDialogTitle from '../common/QRModalDialogTitle';
import ItemsTable from './ItemsTable';
import { ReceiptItem } from './types';

type Props = {
  user: User;
  todaysDate: string;
  items: ReceiptItem[] | null;
  totals: { [key: string]: { total: number; label: string } } | null;
  handleEditClick: () => void;
  handleSaveClick: () => void;
};
const ReceiptReview = ({ items, totals, user, handleEditClick, handleSaveClick }: Props) => {
  if (items == null || totals == null) return <p>Error loading component</p>;
  return (
    <>
      <DialogTitle
        align={'center'}
        color={'#fff'}
        marginBottom={1}
        sx={{ backgroundColor: '#90bbb1' }}
      >
        Please review the final details
      </DialogTitle>
      <QRModalDialogTitle
        fullname={user.fullName}
        address={user.address}
        apt={user.apt ? user.apt : null}
        city={user.city}
        state={user.state}
        postalcode={user.postalcode}
      />
      <ItemsTable receiptItemsList={items} />
      <Typography align='right' paddingRight={1} marginTop={1}>
        <strong>Items Count:</strong> {items.length}
      </Typography>
      {totals &&
        Object.keys(totals).map((itemTotalKey) => {
          return (
            <Typography key={itemTotalKey} align='right' paddingRight={1} marginTop={1}>
              <strong>Total {itemTotalKey}: </strong> {totals[itemTotalKey].total}{' '}
              {totals[itemTotalKey].label}
            </Typography>
          );
        })}
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant='contained' color='success' fullWidth onClick={() => handleSaveClick()}>
          Save
        </Button>
        <Button variant='contained' color='info' fullWidth onClick={() => handleEditClick()}>
          Edit
        </Button>
      </DialogActions>
    </>
  );
};

export default ReceiptReview;
