export type Values = {
  binNumber: number;
  binWeight: number;
  notes: string;
};

export const resetErrorMsg = {
  isError: false,
  message: '',
};
