import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { ReceiptItem } from './types';

type Props = {
  receiptItemsList: ReceiptItem[];
};

const ItemsTable = ({ receiptItemsList }: Props) => {
  return (
    <TableContainer
      component={Paper}
      className='hideScrollBar'
      style={{
        maxHeight: '30vh',
        overflow: 'scroll',
        width: '100%',
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 'bold' }}>Item Name</TableCell>
            <TableCell align='center' style={{ fontWeight: 'bold' }}>
              Quantity
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {receiptItemsList.map((item: ReceiptItem) => {
            return (
              <TableRow
                key={item.item.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component='th' scope='row' style={{ width: '50%' }}>
                  {item.item.name}
                </TableCell>
                <TableCell align='center' style={{ width: '30%' }}>
                  {item.quantity} {item.item.quantitativeLabel}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemsTable;
