import PrintIcon from '@mui/icons-material/Print';
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { BasicPickupBagWithQRCode } from '../types';

type Props = {
  tagData: BasicPickupBagWithQRCode;
  washType: string;
};

const PrintableBagTag = ({ tagData, washType }: Props) => {
  const [printBtnClicked, setPrintBtnClicked] = useState<boolean>(false);
  const printComponentRef = useRef(null);
  const date = new Date();
  const currentDate = date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    onAfterPrint: () => {
      setPrintBtnClicked(true);
    },
  });

  return (
    <>
      <Stack key={`qrcode-${tagData.bagNumber}`} direction={'row'}>
        <Typography
          style={{
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            flex: 2,
            fontSize: '1.2rem',
          }}
        >
          Bin Number: {tagData.bagNumber}
        </Typography>
        <Button onClick={handlePrint} style={{ flex: 1 }}>
          <PrintIcon color={printBtnClicked ? 'success' : 'primary'} fontSize='medium' />
        </Button>
      </Stack>
      <Box display={'none'}>
        <Stack ref={printComponentRef} p={1} display={'flex'} mt={3}>
          <Typography fontWeight={'bold'} fontSize={'28px'} textAlign={'center'}>
            {tagData.name}
          </Typography>
          <Box display={'flex'} justifyContent={'center'}>
            <img src={tagData.qrCodeURL} width={200} height={200} alt='QR Code' />
          </Box>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              Bag #:
            </Typography>
            <Typography>{tagData.bagNumber}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              Weight:
            </Typography>
            <Typography>{tagData.weight}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              Date:
            </Typography>
            <Typography textAlign={'center'}>{currentDate}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              Address:
            </Typography>
            <Typography>{tagData.address}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              City:
            </Typography>
            <Typography>{tagData.city}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              State:
            </Typography>
            <Typography>{tagData.state}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              Postal Code:
            </Typography>
            <Typography>{tagData.postalCode}</Typography>
          </Stack>
          <Stack direction={'row'}>
            <Typography fontWeight={'bold'} flex={1}>
              Wash Type:
            </Typography>
            <Typography>{washType}</Typography>
          </Stack>
          {tagData.aptNum && (
            <Stack direction={'row'}>
              <Typography fontWeight={'bold'} flex={1}>
                Apt Num:
              </Typography>
              <Typography>{tagData.aptNum}</Typography>
            </Stack>
          )}
          <Stack direction={'column'}>
            <Typography fontWeight={'bold'} flex={1}>
              Cleaning Notes:
            </Typography>
            <Typography>{tagData.notes ? tagData.notes : 'N/A'}</Typography>
          </Stack>
          <Stack direction={'column'}>
            <Typography fontWeight={'bold'} flex={1}>
              Delivery Notes:
            </Typography>
            <Typography>{tagData.deliveryNotes ? tagData.deliveryNotes : 'N/A'}</Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default PrintableBagTag;
