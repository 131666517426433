import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { formatDateMonthDay } from '../../../../utils/formatting';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import ArrowOrdering from './common/ArrowOrdering';
import { OSTodo } from '../../../../api/fc-os/types/todos-types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteTodoAPI,
  getPersonalAndTeamTodos,
  updateTodoStatus,
} from '../../../../api/fc-os/todos';
import { Employee } from '../../../../types/types';
import LoadingSpinner from '../../../common/LoadingSpinner';
import toast from 'react-hot-toast';
import { ModalTypes } from '../../createModal/CreateModal.js';
import ConfirmDialog from '../../../common/ConfirmDialog';
type TodoType = 'personal' | 'team' | 'all';

type Props = {
  user: Employee;
  title: string;
  type: TodoType;
  handleEditClick: (item: OSTodo, type: ModalTypes) => void;
};
const TodosCard = ({ user, title, type, handleEditClick }: Props) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [todosList, setTodosList] = useState<OSTodo[]>([]);
  // const [todosListSorted, setTodosListSorted] = useState<OSTodo[]>([])
  const lightGray = theme.palette.primary.light;
  const [status, setStatus] = useState<string>('All');
  const filters = ['All', 'Complete', 'Incomplete'];
  const [order, setOrder] = useState<string>('');
  const [currentRowOrder, setCurrentRowOrder] = useState<string>('');
  const todaysDate = new Date();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedTodoIndex, setSelectedTodoIndex] = useState<null | number>(null);
  const [confirmationInView, setConfirmationInView] = useState<boolean>(false);
  // loading the data
  const {
    isError: todosErrors,
    data: todosData,
    isLoading,
    dataUpdatedAt,
  } = useQuery(['todos'], () => getPersonalAndTeamTodos(user));
  // this is called when the status filter is changed
  const handleStatusChange = (todoStatus: string) => {
    const lowercaseStatus = todoStatus.toLowerCase();
    const filteredData = todosData ? filterAllTodosForType(todosData, type) : [];
    if (lowercaseStatus === 'all') {
      setTodosList(filteredData);
    } else {
      // filtering the list by the status
      const filteredTodosList = filteredData.filter((todo) => {
        return todo.status === lowercaseStatus;
      });
      setTodosList(filteredTodosList);
    }
    setStatus(todoStatus); // Update Status filter state when dropdown value changes
  };
  // This will change the value for the todo "status". Both in the Frontend and Backend
  // checked -> "complete"
  // unchecked -> "incomplete"
  const changeTodoStatus = async (id: string) => {
    // copy the list of todos
    const _todosList = [...todosList];
    // find the todo and update its status
    const indexOfTodo = _todosList.findIndex((todo: OSTodo) => {
      return todo.id === id;
    });
    // if found then copy the todo object
    if (indexOfTodo > -1) {
      // send the update to the backend
      try {
        const _todo = { ..._todosList[indexOfTodo] };
        // change the todo status field
        if (_todo.status === 'complete') {
          _todo.status = 'incomplete';
        } else {
          _todo.status = 'complete';
        }
        // await updateTodoStatus({_todo.id, _todo.status})
        changeTodoStatusMutation.mutate({ todoId: _todo.id, newStatus: _todo.status });
        _todosList[indexOfTodo] = _todo;
      } catch {
        toast.error('Failed to update status');
      }
    }
    setTodosList(_todosList);
  };

  const deleteTodo = (id: number) => {
    deleteTodoMutation.mutate(todosList[id].id);
  };

  const filterAllTodosForType = (todosData: OSTodo[], type: TodoType): OSTodo[] => {
    const filteredList: OSTodo[] = [];

    if (type === 'all') {
      // If type is 'all', simply return all todos
      return todosData;
    }

    // Filter todos based on the specified type
    todosData.forEach((todo) => {
      if (type === 'personal' && todo.personal) {
        filteredList.push(todo);
      } else if (type === 'team' && !todo.personal) {
        filteredList.push(todo);
      }
      // If type is not recognized, do nothing
    });

    return filteredList;
  };

  useEffect(() => {
    if (todosData) {
      const filteredList = filterAllTodosForType(todosData, type);
      setTodosList(filteredList);
    }
  }, [todosData, dataUpdatedAt]);

  // This is the function for handling the menu pop for each todo
  const handleThreeDotClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedTodoIndex(index);
  };
  // function passed to the confirmation dialog component
  // to handle if they accept the deletion
  const handleConfirmationClick = (res: boolean) => {
    if (res && selectedTodoIndex !== null) {
      deleteTodo(selectedTodoIndex);
    }
    setConfirmationInView(false);
  };
  // menu code
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTodoIndex(null);
  };

  const getTodoTableRow = (todo: OSTodo, index: number) => {
    return (
      <TableRow key={todo.id}>
        <TableCell component='th' scope='row' sx={{ padding: '10px 0' }}>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'left'}>
            <Checkbox
              checked={todo.status == 'complete' ? true : false}
              onClick={() => changeTodoStatus(todo.id)}
            />
            <Typography>{todo.title}</Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ padding: '10px 0' }}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
            {todo.dueDate.toDate() < todaysDate && (
              <FmdBadIcon sx={{ mr: 1 }} htmlColor='red'></FmdBadIcon>
            )}
            <Typography
              color={todo.dueDate.toDate() < todaysDate ? 'red' : '#000'}
              textAlign={'center'}
            >
              {formatDateMonthDay(todo.dueDate.toDate().toString())}
            </Typography>
            <IconButton onClick={(event) => handleThreeDotClick(event, index)}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        </TableCell>
        {/* <TableCell sx={{ padding: '10px 0' }}>
                    <Stack direction={'row'}>
                        <IconButton onClick={() => handleEditClick(todo, 'To-Do')}>
                            <EditIcon cursor='pointer' htmlColor={lightGray} />
                        </IconButton>
                        <IconButton onClick={() => deleteTodo(todo.id)}>
                            <DeleteIcon htmlColor='red' />
                        </IconButton>
                    </Stack>
                </TableCell> */}
      </TableRow>
    );
  };

  // order by row lets us know which row we need to order by
  const handleOrderChange = (newOrder: number, orderByRow: string) => {
    const _order = newOrder === 1 ? 'asc' : newOrder === 2 ? 'desc' : '';
    setOrder(_order);
    setCurrentRowOrder(orderByRow);
    // we need to make a copy since sort arranges IN PLACE
    // eslint-disable-next-line
    const _todosList: any = [...todosList];
    if (orderByRow === 'title') {
      // eslint-disable-next-line
      _todosList.sort((a: any, b: any) => {
        if (_order === 'asc') {
          return a.title.localeCompare(b.title);
        } else if (_order === 'desc') {
          return b.title.localeCompare(a.title);
        }
      });
      setTodosList(_todosList);
    } else if (orderByRow === 'due-date') {
      // eslint-disable-next-line
      _todosList.sort((a: any, b: any) => {
        if (_order === 'asc') {
          return a.dueDate - b.dueDate;
        } else if (_order === 'desc') {
          return b.dueDate - a.dueDate;
        }
      });
      setTodosList(_todosList);
    }
  };
  // mutation for changing status
  const changeTodoStatusMutation = useMutation({
    mutationFn: (changeData: { todoId: string; newStatus: string }) => updateTodoStatus(changeData),
    onMutate: async (changeData: { todoId: string; newStatus: string }) => {
      await queryClient.cancelQueries({ queryKey: ['todos'] });
      const previousTodos = queryClient.getQueryData<OSTodo[]>(['todos']);
      if (previousTodos) {
        const _previousTodos = [...previousTodos];
        const foundTodo = _previousTodos.findIndex((todo) => {
          return todo.id === changeData.todoId;
        });
        if (foundTodo > -1) {
          const _todo = _previousTodos[foundTodo];
          _todo.status = changeData.newStatus;
          _previousTodos[foundTodo] = _todo;
          queryClient.setQueryData<OSTodo[]>(['todos'], _previousTodos);
        }
      }
      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onSuccess: (data, variables, context) => {
      if (context && context.previousTodos) {
        queryClient.setQueryData<OSTodo[]>(['todos'], context.previousTodos);
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, finalizedItem, context) => {
      if (context?.previousTodos) {
        queryClient.setQueryData(['todos'], context.previousTodos);
        toast.error('Failed to change todo status');
      }
    },
  });
  // mutation for deleting
  const deleteTodoMutation = useMutation({
    mutationFn: (todoId: string) => deleteTodoAPI(todoId),
    onMutate: async (todoId: string) => {
      handleClose();
      await queryClient.cancelQueries({ queryKey: ['todos'] });
      const previousTodos = queryClient.getQueryData<OSTodo[]>(['todos']);
      if (previousTodos) {
        const _previousTodos = [...previousTodos];
        const foundTodo = _previousTodos.findIndex((todo) => {
          return todo.id === todoId;
        });
        if (foundTodo > -1) {
          const newTodos = _previousTodos.slice(foundTodo, 1);
          queryClient.setQueryData<OSTodo[]>(['todos'], newTodos);
        }
      }
      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onSuccess: (data, variables, context) => {
      if (context && context.previousTodos) {
        const _previousTodos = [...context.previousTodos];
        const foundTodo = _previousTodos.findIndex((todo) => {
          return todo.id === data;
        });
        if (foundTodo > -1) {
          _previousTodos.splice(foundTodo, 1);
          queryClient.setQueryData<OSTodo[]>(['todos'], _previousTodos);
        }
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, todoId, context) => {
      if (context?.previousTodos) {
        queryClient.setQueryData(['todos'], context.previousTodos);
        toast.error('Failed to change todo status');
      }
    },
  });

  return (
    <>
      <Card
        component={Paper}
        sx={{
          maxHeight: { xs: '60vh', md: '70vh' },
          padding: 0,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <CardContent style={{ height: '100%', width: '100%' }}>
          <Grid container height={'100%'} bgcolor={'#fff'} borderRadius={1}>
            <Grid item xs={12} height={'35%'} pt={2}>
              <Stack
                direction={'row'}
                height={'100%'}
                alignItems={'center'}
                justifyContent={'space-around'}
              >
                <Typography>{title}</Typography>
                <Box
                  borderRadius={'50%'}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  bgcolor={lightGray}
                  p={1}
                  width={30}
                  height={30}
                >
                  <Typography color={'#fff'}>{todosList.length}</Typography>
                </Box>
                {type === 'personal' && (
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id='filter-completed-status'>Status</InputLabel>
                    <Select
                      labelId='filter-completed-status'
                      id='completed-status-select'
                      value={status}
                      label='Status'
                      // onChange={selectChange}
                      onChange={(e: SelectChangeEvent<string>) =>
                        handleStatusChange(e.target.value)
                      }
                    >
                      {filters.map((filter) => {
                        return (
                          <MenuItem key={filter} value={filter}>
                            {filter}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} height={'65%'}>
              <TableContainer
                component={Paper}
                style={{ height: '100%' }}
                className={'hideScrollBar'}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Stack direction={'row'}>
                          <Typography
                            textAlign={'center'}
                            fontWeight={
                              currentRowOrder === 'title' && order !== '' ? 'bold' : 'initial'
                            }
                          >
                            Title
                          </Typography>
                          <ArrowOrdering
                            setOrder={handleOrderChange}
                            orderByProp={'title'}
                            activeOrderRow={currentRowOrder}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell sx={{ padding: 0 }}>
                        <Stack direction={'row'} justifyContent={'end'}>
                          <Typography
                            noWrap
                            fontWeight={
                              currentRowOrder === 'due-date' && order !== '' ? 'bold' : 'initial'
                            }
                          >
                            Due Date
                          </Typography>
                          <ArrowOrdering
                            setOrder={handleOrderChange}
                            orderByProp={'due-date'}
                            activeOrderRow={currentRowOrder}
                          />
                        </Stack>
                      </TableCell>
                      {/* <TableCell align='center'>
                                        </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <LoadingSpinner />
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ) : todosErrors ? (
                      <Typography>Error Fetching Todos</Typography>
                    ) : todosList.length > 0 ? (
                      todosList.map((todo, index) => {
                        return getTodoTableRow(todo, index);
                      })
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography>There are no To-Dos</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditClick(
              selectedTodoIndex ? todosList[selectedTodoIndex] : todosList[0],
              'To-Do',
            );
            handleClose();
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <Typography color={'green'}>Edit</Typography>
            </Grid>
            <Grid item xs={2}>
              <EditIcon cursor='pointer' htmlColor={'green'} />
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setConfirmationInView(true);
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <Typography color={'red'}>Delete</Typography>
            </Grid>
            <Grid item xs={2}>
              <DeleteIcon htmlColor='red' />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
      {confirmationInView && (
        <ConfirmDialog
          title={'Please confirm action'}
          message='Are you sure you want to delete this Todo?'
          handleClick={handleConfirmationClick}
        />
      )}
    </>
  );
};

export default TodosCard;
