import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBzUKRbduoAvqulw_2lRf8kqsNh5ECm70E',
  authDomain: 'fresh-clothes.firebaseapp.com',
  databaseURL: 'https://fresh-clothes.firebaseio.com',
  projectId: 'fresh-clothes',
  storageBucket: 'fresh-clothes.appspot.com',
  messagingSenderId: '451824039221',
  appId: '1:451824039221:web:b24cdf451d794cff4d087c',
  measurementId: 'G-RBFXXD7T8V',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);

const auth = getAuth(app);

const functions = getFunctions(app, 'us-central1');

const storage = getStorage(app);

export { auth, db, functions, storage };
