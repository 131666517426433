import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

const ConfirmDialog = ({
  title,
  message,
  handleClick,
}: {
  title: string;
  message: string;
  handleClick: (res: boolean) => void;
}) => {
  return (
    <Dialog open={true} maxWidth='sm' fullWidth>
      <DialogTitle>
        ⚠️ <strong>{title}</strong> ⚠️
      </DialogTitle>
      <Box position='absolute' top={0} right={0}>
        <IconButton onClick={() => handleClick(false)}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color='error' variant='contained' onClick={() => handleClick(false)}>
          Cancel
        </Button>
        <Button color='success' variant='contained' onClick={() => handleClick(true)}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
