import { Box, Grid, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useRef } from 'react';
import PersonalRockRow from './component rows/PersonalRockRow';
import { Employee, Todo } from '../../../../../types/types';
import { RockItem, TodoItem } from '../../../types';
import { ModalTypes } from '../../../createModal/CreateModal.js';
import { OSRock, RockStatus } from '../../../../../api/fc-os/types/rockTypes';
import { updateRockStatus } from '../../../../../api/fc-os/rocksAPI';

type Props = {
  user: Employee;
  owner: string;
  rockTodos: OSRock[];
  setRockTodosList: Dispatch<SetStateAction<OSRock[]>>;
  handleItemEdit: (item: TodoItem | OSRock, type: ModalTypes) => void;
};

const PersonalRocks = ({ user, owner, rockTodos, setRockTodosList, handleItemEdit }: Props) => {
  // const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  // const handleThumbClose = () => {
  //   setAnchorEl(null);
  // };

  const rockStatusChange = async (id: string, newStatus: RockStatus) => {
    const _rockTodosList = [...rockTodos];
    const indexFound = _rockTodosList.findIndex((rockMilestone) => {
      return rockMilestone.id === id;
    });
    if (indexFound > -1) {
      // update the status in the database
      const _rockMilestone = { ..._rockTodosList[indexFound] };
      const rockStatusUpdated = await updateRockStatus(user, _rockMilestone, newStatus);
      console.log(rockStatusUpdated);
      if (rockStatusUpdated) {
        _rockMilestone.status = newStatus;
        _rockTodosList[indexFound] = _rockMilestone;
        setRockTodosList(_rockTodosList);
      }
    }
    // const todoIndex = _rockTodosList.findIndex((todo) => todo.id == id);

    // if (todoIndex > -1) {
    //   const _rockTodo = { ..._rockTodosList[todoIndex] };
    //   _rockTodo.status = newStatus;
    //   console.log({ _rockTodo });

    //   _rockTodosList[todoIndex] = _rockTodo;
    //   setRockTodosList(_rockTodosList)
    // }
    // // handleThumbClose();
  };

  const deleteRock = (rockTodoItem: OSRock) => {
    if (window.confirm('Are you sure?')) {
      const _rockTodosCopy = [...rockTodos];
      const rockTodoIndex = _rockTodosCopy.findIndex((rockTodo) => rockTodo.id == rockTodoItem.id);
      if (rockTodoIndex !== -1) {
        _rockTodosCopy.splice(rockTodoIndex, 1); // Remove 1 element at teamTodoIndex
        setRockTodosList(_rockTodosCopy);
      }
    }
  };

  // DRAGGABLE FUNCTIONS
  // saving references for dragItem and dragOverItem
  // eslint-disable-next-line
  const dragItem = useRef<any>(null);
  // eslint-disable-next-line
  const dragOverItem = useRef<any>(null);
  // handling sorting drag & drop
  const handleSort = () => {
    // duplicate items
    const _itemsSelected = [...rockTodos];
    // remove and save the dragged item content
    const draggedItemContent = _itemsSelected.splice(dragItem.current, 1)[0];
    // switch the position
    _itemsSelected.splice(dragOverItem.current, 0, draggedItemContent);
    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // update the actual array
    setRockTodosList(_itemsSelected);
  };

  return (
    <Box my={4} p={1} sx={{ flexGrow: 1, boxShadow: 5 }}>
      <Typography gutterBottom variant='h6'>
        {owner}
        {/* TODO Replaced with Owner.Name */}
      </Typography>

      {/* SECTION Grid */}
      <Box py={0}>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant='subtitle2' color={'gray'} pl={4}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle2' color={'gray'}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography ml={5} textAlign={'center'} variant='subtitle2' color={'gray'}>
              Due By
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* SECTION V-for / Map */}
      {rockTodos.map((rockTodo, index) => {
        return (
          <Box
            key={`rock-${index}`}
            draggable
            onDragStart={() => (dragItem.current = index)}
            onDragEnter={() => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <PersonalRockRow
              key={rockTodo.id}
              user={user}
              rockTodo={rockTodo}
              deleteRock={deleteRock}
              onStatusChange={rockStatusChange}
              handleEditClick={handleItemEdit}
            />
          </Box>
        );
      })}

      {/* TODO add turnary for blank Page with no Rocks at all */}
    </Box>
  );
};

export default PersonalRocks;
