import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { RockItem, TodoItem } from '../components/fc-os/types';
import { ModalTypes } from '../components/fc-os/createModal/CreateModal';
import { OSTodo } from '../api/fc-os/types/todos-types';
import { OSRock } from '../api/fc-os/types/rockTypes';

// Define your existing state and functions here
interface DashboardContextProps {
  moduleInView: boolean;
  page: string;
  activeButtonIndex: number;
  teamTodosList: TodoItem[];
  rockTodosList: RockItem[];
  editItem: OSTodo | RockItem | OSRock | null;
  editItemType: ModalTypes | null;
  setModuleInView: (moduleInView: boolean) => void;
  setPage: (page: string) => void;
  setActiveButtonIndex: (activeButtonIndex: number) => void;
  setTeamTodosList: Dispatch<SetStateAction<TodoItem[]>>;
  setRockTodosList: Dispatch<SetStateAction<RockItem[]>>;
  setEditItem: (editItem: OSTodo | RockItem | null) => void;
  setEditItemType: (editItemType: ModalTypes | null) => void;
  dashboardButtons: (newPageView: string) => void;

  handleEditItemClick: (item: OSTodo | RockItem | OSRock, type: ModalTypes) => void;
  resetEditItem: () => void;
}

const DashboardContext = createContext<DashboardContextProps | undefined>(undefined);

interface DashboardProviderProps {
  children: React.ReactNode;
}

export const DashboardProvider: React.FC<DashboardProviderProps> = ({ children }) => {
  const TEAM_TODOS: TodoItem[] = [
    {
      id: 1,
      title: 'Apple Team',
      dueDate: new Date('December 20, 2014 11:13:00'),
      status: 'Incomplete',
      description: '',
      type: 'team-todo',
    },
    {
      id: 2,
      title: 'Micrsoft Team',
      dueDate: new Date('October 13, 2034 11:13:00'),
      status: 'Complete',
      description: '',
      type: 'team-todo',
    },
    {
      id: 3,
      title: 'Xaziber Team',
      dueDate: new Date(),
      status: 'Incomplete',
      description: '',
      type: 'team-todo',
    },
  ];

  const ROCK_TODOS: RockItem[] = [
    {
      id: 1,
      title: 'Rock Cherry',
      dueDate: new Date(),
      status: 'Off-Track',
      description: '',
      type: 'rock-milestone',
    },
    {
      id: 2,
      title: 'Rock Tommator',
      dueDate: new Date('October 13, 2034 11:13:00'),
      status: 'On-Track',
      description: '',
      type: 'rock-milestone',
    },
    {
      id: 3,
      title: 'Rock Apple',
      dueDate: new Date(),
      status: 'Off-Track',
      description: '',
      type: 'rock-milestone',
    },
    {
      id: 4,
      title: 'Rock Dog',
      dueDate: new Date(),
      status: 'Done',
      description: '',
      type: 'rock-milestone',
    },

    {
      id: 5,
      title: 'Rock Venom',
      dueDate: new Date('May 13, 2027 11:13:00'),
      status: 'On-Track',
      description: '',
      type: 'rock-milestone',
    },
  ];
  // Your existing state and functions
  const [moduleInView, setModuleInView] = useState<boolean>(false);
  const [page, setPage] = useState<string>('Home');
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const [teamTodosList, setTeamTodosList] = useState<TodoItem[]>([...TEAM_TODOS]);
  const [rockTodosList, setRockTodosList] = useState<RockItem[]>([...ROCK_TODOS]);
  const [editItem, setEditItem] = useState<OSTodo | RockItem | OSRock | null>(null);
  const [editItemType, setEditItemType] = useState<ModalTypes | null>(null);

  const dashboardButtons = (newPageView: string) => {
    if (newPageView == 'Home') {
      setActiveButtonIndex(0);
      setPage(newPageView);
    } else if (newPageView == 'Data') {
      setActiveButtonIndex(1);
      setPage(newPageView);
    } else {
      setActiveButtonIndex(2);
      setPage(newPageView);
    }
  };

  const handleEditItemClick = (item: OSTodo | RockItem | OSRock, type: ModalTypes) => {
    console.log('Dashboard editing activated');
    console.log(item);
    setEditItemType(type);
    setEditItem(item);
    setModuleInView(true);
  };

  const resetEditItem = () => {
    setEditItemType(null);
    setEditItem(null);
    setModuleInView(false);
  };

  return (
    <DashboardContext.Provider
      value={{
        moduleInView,
        page,
        activeButtonIndex,
        teamTodosList,
        rockTodosList,
        editItem,
        editItemType,
        setModuleInView,
        setPage,
        setActiveButtonIndex,
        setTeamTodosList,
        setRockTodosList,
        setEditItem,
        setEditItemType,
        dashboardButtons,

        handleEditItemClick,
        resetEditItem,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useMeetingContext must be used within a MeetingProvider');
  }
  return context;
};
