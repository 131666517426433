import { Skeleton, Stack } from '@mui/material';
import { Box } from '@mui/system';

export const UserTableSkeleton = () => {
  return (
    <Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
    </Box>
  );
};

export const MessagingSkeleton = () => {
  return (
    <Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'30%'} height={60} animation='wave' />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'30%'} height={60} animation='wave' />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
      <Box display='flex'>
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
        <Skeleton variant='text' width={'20%'} height={60} animation='wave' sx={{ ml: 2 }} />
      </Box>
    </Box>
  );
};

export const GetMessagingByUserSkeleton = () => {
  return (
    <Box>
      <Skeleton variant='text' width={'80%'} height={90} animation='wave' />
      <Skeleton variant='text' width={'80%'} sx={{ float: 'right' }} height={90} animation='wave' />
      <Skeleton variant='text' width={'80%'} sx={{ float: 'right' }} height={90} animation='wave' />
      <Skeleton variant='text' width={'80%'} height={90} animation='wave' />
    </Box>
  );
};

export const QRCodeSkeleton = () => {
  return (
    <Box>
      <Box display='flex'>
        <Skeleton variant='rectangular' width={'100%'} height={500} animation='wave' />
      </Box>
    </Box>
  );
};
export const MessageSendingSkeleton = () => {
  return <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' />;
};
export const RouteDaySkeleton = () => {
  return (
    <Stack>
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 5 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
      <Skeleton variant='rectangular' width={'100%'} height={50} animation='wave' sx={{ mt: 1 }} />
    </Stack>
  );
};
