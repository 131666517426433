import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { db } from '../firebase-config';
import { Employee, LaundryItem, Receipt, User } from '../types/types';
import capitalizeFirstLetter from '../utils/capitalize-first-letter';
import formatPhoneNumber from '../utils/format-phone-number';

interface FirebaseUser {
  firstName: string;
  lastName: string;
  lastLoggedIn: Timestamp;
  routeDay: string[];
  status: string;
  washType: string;
  email: string;
  phoneNumber: number;
  address: string;
  city: string;
  apt: string;
  state: string;
  postalcode: string;
  notes?: string;
  deliveryNotes: string;
  deliveryNotesImages: { url: string; description: string }[];
  type?: string;
  itemsList?: LaundryItem[];
  itemMeasurement?: { label: string; name: string; type: string };
}

const createReceipt = async (receiptData: Receipt) => {
  try {
    const newDoc = await addDoc(collection(db, 'receipts'), receiptData);
    return newDoc.id;
  } catch (error) {
    return false;
  }
};

const getAllReceipts = async () => {
  const allReceipts: Receipt[] = [];
  const q = query(collection(db, 'receipts'), orderBy('createdAt', 'asc'));
  const querySnapshot = await getDocs(q);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  querySnapshot.forEach((doc: any) => {
    const docID: string = doc.id;

    const data: Receipt = { docID, ...doc.data() };
    allReceipts.push(data);
  });

  return allReceipts;
};
const getReceiptDetails = async (user: Employee, uid: string | undefined) => {
  if (uid == undefined) throw Error;
  try {
    const docRef = doc(db, 'receipts', uid);
    // eslint-disable-next-line
    const querySnapshot: any = await getDoc(docRef);

    if (querySnapshot.exists()) {
      const docID = querySnapshot.id;

      const data: Receipt = { docID, ...querySnapshot.data() };
      if (
        'weight' in data.totals &&
        data.totals['weight'].dryTotal !== undefined &&
        data.totals['weight'].dryTotal >= 0
      ) {
        return data;
      } else return false;
    } else {
      return undefined;
    }
  } catch (error) {
    throw Error;
  }
};

const getCorporateCustomers = async () => {
  const allCorporateCustomers: User[] = [];
  const q = query(collection(db, 'users'), where('type', '==', 'corporate'));
  const querySnapshot = await getDocs(q);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  querySnapshot.forEach((doc: any) => {
    const docID: string = doc.id;
    const {
      firstName,
      lastName,
      lastLoggedIn,
      routeDay,
      status,
      washType,
      email,
      phoneNumber,
      address,
      apt,
      city,
      state,
      postalcode,
      notes,
      deliveryNotes,
      deliveryNotesImages,
      type,
      itemsList,
      itemMeasurement,
    }: FirebaseUser = doc.data();
    let dateJoined;
    let dateUnformatted;
    if (lastLoggedIn) {
      dateJoined = lastLoggedIn
        .toDate()
        .toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      dateUnformatted = lastLoggedIn.toDate();
    }
    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    let formattedWashType;
    if (washType) formattedWashType = capitalizeFirstLetter(washType);

    let formattedStatus;
    if (status) {
      if (status === 'active') formattedStatus = 'Active';
      if (status === 'inactive') formattedStatus = 'Inactive';
      if (status === 'outOfArea') formattedStatus = 'Out Of Area';
      if (status === 'loginCreated') formattedStatus = 'Login Created';
      if (status === 'terminal') formattedStatus = 'Terminal';
      if (status === 'pending') formattedStatus = 'Pending';
      if (status === 'admin') formattedStatus = 'Admin';
    }
    if (!email) return;
    const user = {
      docID: docID,
      id: email,
      fullName: `${firstName} ${lastName}`,
      dateJoined,
      dateUnformatted,
      routeDay,
      status: formattedStatus,
      washType: formattedWashType,
      email,
      phoneNumber: formattedPhoneNumber,
      phoneNumberApi: phoneNumber,
      address,
      apt,
      city,
      state,
      postalcode: postalcode,
      type: type ? type : 'residential',
      ...(itemsList && { itemsList }),
      ...(itemMeasurement && { itemMeasurement }),
      ...(deliveryNotes && { deliveryNotes }),
      ...(deliveryNotesImages && { deliveryNotesImages }),
      ...(notes && { notes }), // add to object only if exists
    };

    allCorporateCustomers.push(user);
  });

  return allCorporateCustomers;
};

const updateReceiptInvoice = async (receiptUID: string) => {
  const docRef = doc(db, 'receipts', receiptUID);
  const data = { invoiceDate: Timestamp.now() };
  return updateDoc(docRef, data)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const updateReceiptDryWeight = async (receiptUID: string, data: Receipt) => {
  const docRef = doc(db, 'receipts', receiptUID);
  return updateDoc(docRef, { ...data, finalized: true })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export {
  createReceipt,
  getAllReceipts,
  getCorporateCustomers,
  getReceiptDetails,
  updateReceiptDryWeight,
  updateReceiptInvoice,
};
