import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { Dispatch, SetStateAction, useState } from 'react';

type DataItem = {
  id: number;
  status: string;
  owner: string;
  title: string;
  goal: number | boolean;
  goalOrentationRate: string;
  goalOrentation: string;
  description: string;
  average: number;
  unit: string;
  currency: string;
  total: number;
  minValue: number | null;
  maxValue: number | null;
  rollingWeeks: {
    weekStart: Date | string;
    weekEnd: Date | string;
    value: number | boolean | string | null;
  }[];
};

type Props = {
  singleDataValue: DataItem;
  updateDataTableOffDataCells: Dispatch<SetStateAction<DataItem>>;
  setDataCellClickedOn: Dispatch<SetStateAction<number>>;
  rollingWeekIndex: number;
  startDate: string;
  view: string;
  setView: React.Dispatch<React.SetStateAction<number[]>>;
  handleBlur: () => void;
};

const IndividualCell = ({
  singleDataValue,
  rollingWeekIndex,
  updateDataTableOffDataCells,
  view,
  // startDate,
  // setView,
  handleBlur,
}: Props) => {
  const [currentValue, setCurrentValue] = useState<number | boolean | string>(
    singleDataValue.rollingWeeks[rollingWeekIndex].value === ''
      ? ''
      : singleDataValue.rollingWeeks[rollingWeekIndex].value || false,
  );

  const formatValue = (currentValue: number | boolean | string) => {
    if (currentValue === true) {
      return 'Yes';
    }
    if (currentValue === false) {
      return 'No';
    }
    if (currentValue === '') {
      return '';
    }
    return currentValue;
  };

  const handleValueChange = (e: string) => {
    const newValue = e;

    // Check if the new value is either a number or an empty string
    // NOTE I believe this newValue = '' is causing problems with the use effect because it wont run updateDataTableOffDataCells.

    // Update the current value in the component state
    setCurrentValue('');

    // Create a shallow copy of the data for the specific cell
    const _dataCellValue = { ...singleDataValue };

    // Update the value in the rollingWeeks array at the specified index
    _dataCellValue.rollingWeeks[rollingWeekIndex].value =
      newValue === '' ? '' : isNaN(parseInt(newValue)) ? '' : parseInt(newValue);

    // Call a function to update the parent component's data
    updateDataTableOffDataCells(_dataCellValue);

    // Log the updated data for debugging
    console.log(_dataCellValue, 'new value');
    setCurrentValue(newValue);
  };

  // const handleYesNoValueChange = (e: boolean | number | string) => {
  //   const newValue = e;

  //   if (newValue === 'true') {
  //     return setCurrentValue(true);
  //   }
  //   if (newValue === 'false') {
  //     return setCurrentValue(false);
  //   }

  //   // Check if the new value is either a number or an empty string
  //   // NOTE I believe this newValue = '' is causing problems with the useEffect because it wont run updateDataTableOffDataCells.
  //   if (newValue === '') {
  //     // Update the current value in the component state
  //     setCurrentValue(newValue);

  //     // Create a shallow copy of the data for the specific cell
  //     const _dataCellValue = { ...singleDataValue };

  //     // Update the value in the rollingWeeks array at the specified index
  //     _dataCellValue.rollingWeeks[rollingWeekIndex].value =
  //       newValue === '' ? '-' : isNaN(parseInt(newValue)) ? '-' : parseInt(newValue);

  //     // Call a function to update the parent component's data
  //     updateDataTableOffDataCells(_dataCellValue);

  //     // Log the updated data for debugging
  //     console.log(_dataCellValue, 'new value');
  //     return;
  //   }
  //   setCurrentValue(newValue);

  // };

  const handleYesNoValueChange = (e: boolean | number | string) => {
    let parsedValue: boolean | number;

    // console.log(parsedValue, 'What is parsed valu');

    if (typeof e === 'boolean') {
      parsedValue = e;
    } else if (typeof e === 'string') {
      parsedValue = e === 'true';
    } else if (typeof e === 'number') {
      parsedValue = e;
    } else {
      // Handle invalid values or throw an error
      return; // or throw new Error('Invalid value type');
    }

    setCurrentValue(parsedValue);

    const _dataCellValue = { ...singleDataValue };
    _dataCellValue.rollingWeeks[rollingWeekIndex].value =
      typeof parsedValue === 'number'
        ? parsedValue
        : typeof parsedValue === 'boolean'
        ? parsedValue
        : '-';

    //       newValue === '' ? '-' : isNaN(parseInt(newValue)) ? '-' : parseInt(newValue);

    updateDataTableOffDataCells(_dataCellValue);

    console.log(_dataCellValue, 'new value');
  };

  return (
    <>
      {/* TODO take startDate and see if I can do a comparsion check so I can create an X for Days that have not happend */}
      {/* Start date and end  */}
      {/* {startDate and endDate > new Date()? x} */}
      {view === 'read' ? (
        // TEXT FIELD MAYBE
        <Box
          maxWidth={55}
          minWidth={55}
          sx={{ overflowX: 'hidden', padding: currentValue === '' ? '8px' : '0' }}
        >
          {currentValue === '' ? (
            <Typography textAlign={'center'}>-</Typography> // Adjust the height and background color as needed
          ) : singleDataValue.goal <= currentValue ? (
            <Typography textAlign={'center'} color={currentValue === '' ? 'black' : 'green'}>
              {formatValue(currentValue)}
            </Typography>
          ) : singleDataValue.goal > currentValue ? (
            <Typography textAlign={'center'} color={currentValue === '' ? 'black' : 'red'}>
              {formatValue(currentValue)}
            </Typography>
          ) : (
            <Typography textAlign={'center'}>{formatValue(currentValue)}</Typography>
          )}
        </Box>
      ) : (
        <Box maxWidth={55} minWidth={55}>
          {/* LOOK into onFocus */}

          {typeof singleDataValue.goal === 'boolean' ? (
            <FormControl margin='none'>
              <InputLabel id='demo-simple-select-label'>Y/N</InputLabel>
              <Select
                SelectDisplayProps={{ style: { paddingRight: '24px' } }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={currentValue}
                label='Y/N'
                onBlur={handleBlur}
                onChange={(e) => handleYesNoValueChange(e.target.value)}
              >
                <MenuItem value={'true'}>Yes</MenuItem>
                <MenuItem value={'false'}>No</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              sx={{ p: 0, m: 0, maxWidth: 50 }}
              variant='standard'
              onBlur={handleBlur}
              value={currentValue}
              onChange={(e) => handleValueChange(e.target.value)}
            />
          )}
        </Box>
      )}
    </>
  );
};
export default IndividualCell;
