import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ErrorType } from '../../../../types/types';
import { HeadlineData } from '../../Meetings/components/Sections/Headlines/HeadlineData';
import s from './style.module.scss';

import { validateMinRequirements } from '../../../../utils/validating';
import { HeadlineItem } from '../../types';

const HeadlineFrom = ({ handleCancel }: { handleCancel: () => void }) => {
  // Input States
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  // ERROR Handling
  const resetErrorMessage = { isError: false, message: '' };
  const [titleError, setTitleError] = useState<ErrorType>(resetErrorMessage);

  const ERROR_MESSAGES = {
    title: '3 Characters Minimum',
    dueDate: 'Must be correct format dd/mm/yyyy',
  };

  const resetAllValues = () => {
    setTitle('');
    setDescription('');
    setTitleError(resetErrorMessage);
  };

  const handleSubmit = async () => {
    try {
      if (title.length < 1 || !titleError.isError) {
        const newHeadline: HeadlineItem = {
          id: HeadlineData.length + 1,
          title: title,
          description: description,
          owner: 'Tyler Rice',
          status: 'Good',
          team: 'Fresh Clothes',
          archived: false,
        };

        HeadlineData.push(newHeadline);
        toast.success('Successfully Created new Headline');
        resetAllValues();
        handleCancel();
      }
    } catch (error) {
      toast.error;
    }
  };

  return (
    <>
      <DialogContent>
        <Box>
          <Box display={'flex'} alignItems={'end'} mt={2}>
            <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              required
              label='Name the Headline'
              variant='standard'
              value={title}
              error={titleError.isError}
              helperText={titleError.message}
              onChange={(e) => {
                setTitle(e.target.value);
                if (!validateMinRequirements(e.target.value, 3))
                  setTitleError({ isError: true, message: ERROR_MESSAGES.title });
                else setTitleError(resetErrorMessage);
              }}
            ></TextField>
          </Box>
        </Box>
        <Box mt={2}>
          <TextareaAutosize
            placeholder='Description'
            minRows={5}
            className={s.textWidth}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></TextareaAutosize>
        </Box>

        <Box mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <InputLabel shrink={true}>Team</InputLabel>
            <FormControl variant='standard' sx={{ display: 'flex', m: 1 }}>
              <Select id='demo-simple-select-standard' value={'Fresh Clothes'} label='Age'>
                <MenuItem value='Fresh Clothes'>Fresh Clothes Leadership</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color='inherit'
          sx={{ px: 2, mx: 2 }}
          variant='contained'
          onClick={() => {
            resetAllValues();
            handleCancel();
          }}
        >
          Cancel
        </Button>

        <Button
          disabled={title.length < 1 || titleError.isError}
          fullWidth
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default HeadlineFrom;
