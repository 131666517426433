import { useQuery } from 'react-query';
import { useAuth } from '../context/AuthContext';
import { Employee } from '../types/types';
import { getUsersForPickup } from '../api/laundry-pickup/users';
import { weekdays } from '../constants';
import LaundryPickup from '../components/laundry-pickup';
import { QRCodeSkeleton } from '../components/skeletons/Skeletons';

const LaundryPickupPage = () => {
  const { user }: { user: Employee } = useAuth();
  // get todays day of the week
  const date = new Date();
  const currentDay = weekdays[date.getDay()];
  // we need to pull the appropriate user's based on the type of user
  const { isError, data } = useQuery(['usersWithLaundryPickup', user.uid], () =>
    getUsersForPickup(user, currentDay),
  );
  // superadmin -> gets EVERY USER
  // owner/manager -> all Users for their store
  // driver -> only users for that delivery date
  if (isError) return <p>error</p>;
  if (data) {
    return <LaundryPickup data={data} loggedUser={user} />;
  }
  return <QRCodeSkeleton />;
};

export default LaundryPickupPage;
