import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { ErrorType } from '../../../../types/types';
import s from '../../styles.module.scss';
import { ReceiptItem } from './types';

const UnknownItemForm = ({
  addItemToParentList,
}: {
  addItemToParentList: (unknowItem: ReceiptItem) => void;
}) => {
  const [itemName, setItemName] = useState<string>('');
  const [quantitativeType, setQuantitativeType] = useState<string>('weight');
  const [quantity, setQuantity] = useState<number | string>(0);
  // ERRORS
  const [itemNameError, setItemNameError] = useState<ErrorType>({ isError: false, message: '' });
  const [quantityError, setQuantityError] = useState<ErrorType>({ isError: false, message: '' });

  const changeItemName = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isEmpty(value)) {
      setItemNameError({ isError: true, message: 'Name cannot be empty.' });
    } else {
      setItemNameError({ isError: false, message: '' });
    }
    setItemName(value);
  };
  // Function used by the select drop down
  const handleItemSelectChange = (event: SelectChangeEvent) => {
    setQuantitativeType(event.target.value);
  };
  const isEmpty = (str: string) => {
    return !str || str.length === 0;
  };

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numValue = parseInt(value);
    if (isNaN(numValue)) {
      setQuantityError({ isError: true, message: 'Must be a number value' });
      setQuantity('');
    } else {
      setQuantityError({ isError: false, message: '' });
      setQuantity(numValue);
    }
  };

  const handleItemAdd = () => {
    if (isEmpty(itemName)) {
      setItemNameError({ isError: true, message: 'Name cannot be empty.' });
      return;
    }
    const type = typeof quantity;
    if (type == 'string') {
      const numValue = parseInt(String(quantity));
      if (isNaN(numValue)) {
        setQuantityError({ isError: true, message: 'Must be a number value.' });
        setQuantity('');
        return;
      }
    } else {
      if (Number(quantity) <= 0) {
        setQuantityError({ isError: true, message: 'Must be a number value greater than 0.' });
        setQuantity('');
        return;
      }
    }
    addItemToParentList({
      item: {
        name: itemName,
        description: 'Manually added item',
        notes: 'Manually added item',
        quantitativeType: quantitativeType,
        quantitativeLabel: quantitativeType == 'weight' ? 'lbs' : 'count',
        price: 0,
      },
      quantity: Number(quantity),
    });
  };

  return (
    <Box className={s.unknownItemForm}>
      <p>Please Enter Item Details</p>
      <TextField
        value={itemName}
        name='name'
        placeholder='Item Name'
        onChange={changeItemName}
        error={itemNameError.isError}
        helperText={itemNameError.message}
      />
      <FormControl fullWidth>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={quantitativeType}
          label='Item'
          onChange={handleItemSelectChange}
        >
          <MenuItem value={'weight'}>weight (lbs)</MenuItem>
          <MenuItem value={'count'}>count (ct)</MenuItem>
        </Select>
      </FormControl>
      <TextField
        value={quantity}
        name='quantity'
        onChange={handleQuantityChange}
        error={quantityError.isError}
        helperText={quantityError.message}
      />
      <Button onClick={handleItemAdd}>Add Item</Button>
    </Box>
  );
};

export default UnknownItemForm;
