import ArticleIcon from '@mui/icons-material/Article';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import ITEM_DATA from './data/ITEM_DATA';
import s from './styles.module.scss';

const Layout = () => {
  const drawerWidth = 180;

  const [mobileOpen, setMobileOpen] = useState(false);

  const { pathname } = useLocation();

  const { logout, user } = useAuth();

  const pageName = pathname === '/' ? 'MESSAGING' : pathname.substring(1).toUpperCase();

  const drawer = (
    <Box>
      <Toolbar />
      <Box display='flex' justifyContent='center'>
        <img className={s.logo} src='/logo.png' alt='Fresh Clothes Logo' />
      </Box>
      <List>
        {ITEM_DATA.map((item) => {
          if (item.permissions?.status.includes(user.status)) {
            return (
              <ListItem
                key={item.title}
                disablePadding
                sx={{
                  display: 'block',
                  backgroundColor: item.link === pathname ? 'rgb(112, 156, 147)' : 'inherit',
                }}
                onClick={() => setMobileOpen(false)}
              >
                <Link
                  to={`${item.link}`}
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </Link>
              </ListItem>
            );
          } else if (
            user.title &&
            item.permissions?.titles &&
            item.permissions.titles.includes(user.title)
          ) {
            return (
              <ListItem
                key={item.title}
                disablePadding
                sx={{
                  display: 'block',
                  backgroundColor: item.link === pathname ? 'rgb(112, 156, 147)' : 'inherit',
                }}
                onClick={() => setMobileOpen(false)}
              >
                <Link
                  to={`${item.link}`}
                  style={{
                    color: '#fff',
                    textDecoration: 'none',
                  }}
                >
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </Link>
              </ListItem>
            );
          } else return;
        })}
        <ListItem
          disablePadding
          sx={{
            display: 'block',
          }}
          onClick={() => setMobileOpen(false)}
        >
          <a
            href={
              'https://www.notion.so/Fresh-Clothes-Operations-Hub-a90f6007fd524e6a82fb75ce42b4c65f'
            }
            rel='noopener noreferrer'
            target='_blank'
            style={{
              color: '#fff',
              textDecoration: 'none',
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon htmlColor='#fff' />
              </ListItemIcon>
              <ListItemText primary={'Handbook'} />
            </ListItemButton>
          </a>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={() => setMobileOpen(!mobileOpen)}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography noWrap component='div'>
            Hello,{' '}
            <strong>
              {user.firstName} {user.lastName}
            </strong>
          </Typography>
          <Typography noWrap component='div'>
            Fresh Clothes - <strong>{pageName}</strong>
          </Typography>
          <IconButton onClick={() => logout()}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={() => setMobileOpen(!mobileOpen)}
          PaperProps={{ sx: { backgroundColor: 'rgb(146, 188, 178)' } }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          PaperProps={{ sx: { backgroundColor: 'rgb(146, 188, 178)' } }}
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            padding: '100px',
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{ flexGrow: 1, px: 1.5, py: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
