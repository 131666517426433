import { Box, Stack, Typography } from '@mui/material';
import DataTable from './Data/DataTable';

const adjectives = ['Mighty', 'Brave', 'Fearless', 'Cunning'];
const nouns = ['Zeus', 'Jupiter', 'Apollo', 'Mars', 'Neptune', 'Athena'];
function getRandomElement(array: string[], isNum?: boolean) {
  const randomIndex: number = Math.floor(Math.random() * array.length);
  if (isNum) {
    return randomIndex;
  }
  return array[randomIndex];
}
function generateName() {
  const generatedName = `${getRandomElement(adjectives)}-${getRandomElement(nouns)}`;
  return generatedName.toLowerCase();
}
// NOTE This generates the Data rows
export function generateRollingWeeksArray(numberOfWeeks: number, random: number | boolean | null) {
  const rollingWeeks = [];

  //  NOTE Now that I got Headers working with week logic. I can either put this here with more if and call this
  // Or there might be a better way but this will create rolling array amount

  // TODO passed string to Headers give me the same number of generated data

  // Orginal way let i = 0; i < numberOfWeeks ; i++
  for (let i = numberOfWeeks; i > 0; i--) {
    const currentDate = new Date();
    const weekStart = new Date(currentDate);
    weekStart.setDate(currentDate.getDate() + i * 7); // Increment by 7 days for each week

    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 4); // Assume a week is 5 days (adjust as needed)

    const value =
      typeof random === 'number'
        ? Math.floor(Math.random() * 100) + 1
        : i % 5 === 0
          ? Math.random() < 0.5
          : Math.random() < 0.5;

    rollingWeeks.push({
      weekStart,
      weekEnd,
      value,
    });
  }
  // rollingWeeks.reverse()
  return rollingWeeks;
}

// NOTE needed for currentYear headers get called by weeksInYear
function getWeekNumber(d: Date) {
  // Copy date so don't modify original
  d = new Date(+d);
  d.setHours(0, 0, 0, 0);
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  // Get first day of year
  const yearStart = new Date(d.getFullYear(), 0, 1);
  // Calculate full weeks to nearest Thursday
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7); // Return array of year and week number
  return [d.getFullYear(), weekNo];
}

// NOTE need for currentYear always gets the right amount of weeks for each year
function weeksInYear(year: number) {
  const month = 11;
  let day = 31;
  let week: number;

  // Find week that 31 Dec is in. If is first week, reduce date until
  // get previous week.
  do {
    const d = new Date(year, month, day--);
    week = getWeekNumber(d)[1];
  } while (week == 1);

  return week;
}

// NOTE needed for YTD
function getWeeksFromStartOfYear(): number {
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1); // January 1st of the current year

  // Calculate the difference in milliseconds between currentDate and the start of the year
  const timeDiff = currentDate.getTime() - startOfYear.getTime();

  // Convert milliseconds to weeks (1 week = 7 days = 7 * 24 * 60 * 60 * 1000 milliseconds)
  const weeks = Math.ceil(timeDiff / (7 * 24 * 60 * 60 * 1000));

  console.log(weeks, 'weeks for YTD');

  return weeks;
}

// NOTE returns weeks for custom
function getWeeksCustom(customStartDate: Date, customEndDate: Date): number {
  const _customStartDate = customStartDate;
  const _customEndDate = customEndDate;

  // Calculate the difference in milliseconds between currentDate and the start of the year
  const timeDiff = _customEndDate.getTime() - _customStartDate.getTime();

  // Convert milliseconds to weeks (1 week = 7 days = 7 * 24 * 60 * 60 * 1000 milliseconds)
  const weeks = Math.ceil(timeDiff / (7 * 24 * 60 * 60 * 1000));

  return weeks;
}

// NOTE used for QTD and Current Quarter
function getStartDateForQuarters() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentQuarter = Math.ceil(currentMonth / 3);
  const currentYear = currentDate.getFullYear();
  if (currentQuarter == 1) {
    console.log('first quarter');
    const quarterStartDate = new Date(`January 1, ${currentYear}`);
    return quarterStartDate;
  } else if (currentQuarter == 2) {
    console.log('se quarter');
    const quarterStartDate = new Date(`April 1, ${currentYear}`);
    return quarterStartDate;
  } else if (currentQuarter == 3) {
    console.log('tjr quarter');
    const quarterStartDate = new Date(`July 1, ${currentYear}`);
    return quarterStartDate;
  } else {
    console.log('ffour quarter');
    const quarterStartDate = new Date(`October 1, ${currentYear}`);
    return quarterStartDate;
  }
}

// NOTE needed for QTD
function getWeeksFromStartOfQuater(): number {
  const currentDate = new Date();
  // const currentQuarter = Math.ceil(currentMonth / 3);
  const quarterStartDate = getStartDateForQuarters(); // Gets start date for current quarter
  // Calculate the difference in milliseconds between currentDate and the start of quarter
  const timeDiff = currentDate.getTime() - quarterStartDate.getTime();
  // Convert milliseconds to weeks (1 week = 7 days = 7 * 24 * 60 * 60 * 1000 milliseconds)
  const weeks = Math.ceil(timeDiff / (7 * 24 * 60 * 60 * 1000));

  console.log(weeks, 'weeks for QTD');

  return weeks;
}

// NOTE needed for Current Quarter and QTD
// const quarterOfYear = (date: Date) => {

//   const month = date.getMonth();

//   if (month < 3) {

//     return 1;

//   } else if (month < 6) {

//     return 2;

//   } else if (month < 9) {

//     return 3;

//   } else {

//     return 4;
//   }
// }

// NOTE the amount of weeks in a Quarter because some have 13 and some have 14
function getTotalWeeksInQuarter() {
  const today = new Date('2024-02-20');
  const currentMonth = today.getMonth();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3; // Find the starting month of the current quarter

  const quarterStartDate = new Date(today.getFullYear(), quarterStartMonth, 1); // Set the start date of the current quarter
  let nextq: Date;
  if (quarter == 4) {
    nextq = new Date(today.getFullYear() + 1, 1, 1);
  } else {
    nextq = new Date(today.getFullYear(), quarter * 3, 1);
  }
  const millis1 = quarterStartDate.getTime(); // If I change this to today.getTime() it will give me 6 with fake date
  const millis2 = nextq.getTime();
  const weekDiff = Math.ceil((millis2 - millis1) / (1000 * 60 * 60 * 24 * 7));
  // console.log('Weeks until next quarter:', weekDiff);

  return weekDiff;
}

// Example usage:
// const weeksFromStartOfYear = getWeeksFromStartOfYear();
// console.log(`Number of weeks from the start of the year until now: ${weeksFromStartOfYear}`);

// NOTE This generates the header date rows
export function generateRollingWeeksHeaderArray(
  dateRangeType: string,
  customStartDate: string | null,
  customEndDate: string | null,
) {
  const dateStrings = [];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear(); // Dont do new Date().getFullYear() you get 1969
  const firstDayOfYear = new Date(currentYear, 0, 1); // January 1st
  // const lastDayOfYear = new Date(currentYear, 11, 31); // December 31st
  // const oneDay: number = 1000 * 60 * 60 * 24; // Number of milliseconds in a day

  // Include the current week by starting the loop from 0
  if (dateRangeType == 'Rolling') {
    for (let i = 12; i >= 0; i--) {
      const dayOfWeek = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

      // Calculate the difference in days to reach Monday (assuming Sunday as the first day of the week)
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      const weekStart = new Date(currentDate);
      weekStart.setDate(currentDate.getDate() - diffToMonday); // Adjust to get Monday of the current week
      weekStart.setDate(weekStart.getDate() + i * 7); // Apply the weekly offset after getting Monday

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6); // Assume a week is 5 days (adjust as needed) //Change to 4 for Work Week/6 for Mon - Sun

      const startString = weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const endString = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

      dateStrings.push(`${startString} - ${endString}`);
    }
  } else if (dateRangeType == 'Current Year') {
    const numberOfWeeksInYear = weeksInYear(currentYear);
    for (let i = numberOfWeeksInYear; i >= 0; i--) {
      const dayOfWeek = firstDayOfYear.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      const weekStart = new Date(firstDayOfYear);

      weekStart.setDate(firstDayOfYear.getDate() - diffToMonday); // Adjust to get Monday of the current week
      weekStart.setDate(weekStart.getDate() + i * 7); // Apply the weekly offset after getting Monday

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6); // Change to 4 for Work Week/6 for Mon - Sun
      const startString = weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const endString = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

      dateStrings.push(`${startString} - ${endString}`);
    }
  } else if (dateRangeType == 'YTD') {
    // NOTE / TODO this one I had to do an forLoop forward and reverse to Jan 1 instead of week Jan 8
    console.log('yTd');
    const numberOfWeeksForYTD = getWeeksFromStartOfYear();
    for (let i = 0; i < numberOfWeeksForYTD; i++) {
      const dayOfWeek = firstDayOfYear.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      const weekStart = new Date(firstDayOfYear);

      weekStart.setDate(firstDayOfYear.getDate() - diffToMonday); // Adjust to get Monday of the current week
      weekStart.setDate(weekStart.getDate() + i * 7); // Apply the weekly offset after getting Monday

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6); // Change to 4 for Work Week/6 for Mon - Sun
      const startString = weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const endString = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

      dateStrings.push(`${startString} - ${endString}`);
      dateStrings.reverse();
    }
  } else if (dateRangeType == 'Current Quarter') {
    console.log('current quarter');
    const totalWeeksInQuarter = getTotalWeeksInQuarter();

    // TODO com back and fix
    const quarterStartDate = getStartDateForQuarters();

    console.log(quarterStartDate);

    for (let i = totalWeeksInQuarter; i >= 0; i--) {
      const weekStart = new Date(quarterStartDate);
      const dayOfWeek = weekStart.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      weekStart.setDate(weekStart.getDate() - diffToMonday); // Adjust to get Monday of the current week
      weekStart.setDate(weekStart.getDate() + i * 7); // Apply the weekly offset after getting Monday

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6); // Change to 4 for Work Week/6 for Mon - Sun
      const startString = weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const endString = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

      dateStrings.push(`${startString} - ${endString}`);
    }
  } else if (dateRangeType == 'QTD') {
    console.log('QTD');
    const numberOfWeeksForQTD = getWeeksFromStartOfQuater();
    for (let i = 0; i < numberOfWeeksForQTD; i++) {
      const dayOfWeek = firstDayOfYear.getDay();
      const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

      const weekStart = new Date(firstDayOfYear);

      weekStart.setDate(firstDayOfYear.getDate() - diffToMonday); // Adjust to get Monday of the current week
      weekStart.setDate(weekStart.getDate() + i * 7); // Apply the weekly offset after getting Monday

      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6); // Change to 4 for Work Week/6 for Mon - Sun
      const startString = weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      const endString = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

      dateStrings.push(`${startString} - ${endString}`);
      dateStrings.reverse();
    }
  } else {
    console.log('custom');
    if (customStartDate && customEndDate != null) {
      const customStartDateInDateForm = new Date(customStartDate);
      const customEndDateInDateFrom = new Date(customEndDate);
      const numberofWeeksForCustom = getWeeksCustom(
        customStartDateInDateForm,
        customEndDateInDateFrom,
      );
      console.log(numberofWeeksForCustom, 'weeks fo cusom');

      for (let i = numberofWeeksForCustom; i > 0; i--) {
        const dayOfWeek = customStartDateInDateForm.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

        // Calculate the difference in days to reach Monday (assuming Sunday as the first day of the week)
        const diffToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

        const weekStart = new Date(customStartDateInDateForm);
        weekStart.setDate(customStartDateInDateForm.getDate() - diffToMonday); // Adjust to get Monday of the current week
        weekStart.setDate(weekStart.getDate() + i * 7); // Apply the weekly offset after getting Monday

        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6); // Assume a week is 5 days (adjust as needed) //Change to 4 for Work Week/6 for Mon - Sun

        const startString = weekStart.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
        });
        const endString = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

        dateStrings.push(`${startString} - ${endString}`);
      }
    }
  }

  return dateStrings;
}

export const generateData = (number: number) => {
  const status = ['On-Track', 'Need Work', 'Off-Track'];

  const units = ['Currency', 'Percentage', 'Number', 'Yes/No', 'Time'];
  const currencies = ['$', '€'];
  const goalOrentationRates = [
    'Greater than or equal to Goal',
    'Inside min and max',
    'Outside min and max',
    'Greater than Goal',
    'Equal to Goal',
  ];
  const goalOrentations = ['T4W & T13W Goal Calculation', 'T4W Goal Calculation'];

  const array = [];

  for (let i = 1; i <= number; i++) {
    const random = i % 2 ? true : (getRandomElement(Array(100), true) as number);
    const newData: {
      id: number;
      status: string;
      owner: string;
      title: string;
      goal: number | boolean;
      average: number;
      goalOrentationRate: string;
      goalOrentation: string;
      description: string;
      unit: string;
      currency: string;
      total: number;
      minValue: number | null;
      maxValue: number | null;
      rollingWeeks: {
        weekStart: Date | string;
        weekEnd: Date | string;
        value: number | boolean;
      }[];
    } = {
      id: i,
      status: getRandomElement(status) as string,
      owner: 'https://i.pravatar.cc/150?img=' + i,
      title: generateName(),
      goal: random,
      average: 0,
      goalOrentationRate: getRandomElement(goalOrentationRates) as string,
      goalOrentation: getRandomElement(goalOrentations) as string,
      unit: getRandomElement(units) as string,
      currency: getRandomElement(currencies) as string,
      description: '',
      total: getRandomElement(Array(1000), true) as number,
      minValue: getRandomElement(Array(1000), true) as number,
      maxValue: getRandomElement(Array(1000), true) as number,
      rollingWeeks: generateRollingWeeksArray(13, random),
    };
    array.push(newData);
  }

  return array;
};

// const DATATABLEDATA: {
//   id: number;
//   status: string;
//   owner: string;
//   title: string;
//   goal: number | boolean;
//   goalOrentationRate: string;
//   goalOrentation: string;
//   description: string;
//   average: number;
//   unit: string;
//   total: number;
//   rollingWeeks: {
//     weekStart: Date | string;
//     weekEnd: Date | string;
//     value: number | boolean;
//   }[];
// }[] = [
//     {
//       id: 1,
//       status: 'On-Track',
//       owner: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80',
//       title: 'Issues Report',
//       goal: 0,
//       average: 0.33,
//       goalOrentationRate: 'Greater than or equal to Goal',
//       goalOrentation: 'T4W & T13W Goal Calculation',
//       unit: 'Percentage',
//       description: '',
//       total: 4,
//       rollingWeeks: [
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 8
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 20
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 30
//         },
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 8
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 20
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 30
//         },
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 8
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 20
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 30
//         },
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 8
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 20
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 30
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 30
//         }
//       ]
//     },
//     {
//       id: 2,
//       status: 'Need Work',
//       owner: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80',
//       title: 'Door Cards Hung',
//       goal: 90,
//       average: 100,
//       goalOrentationRate: 'Greater than or equal to Goal',
//       goalOrentation: 'T4W & T13W Goal Calculation',
//       description: '',
//       unit: 'number',
//       total: 1076,
//       rollingWeeks: [
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 1
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 2
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 3
//         },
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 1
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 2
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 3
//         },
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 1
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 2
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 3
//         },
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 1
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 2
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 3
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 2
//         },
//       ]
//     },
//     {
//       id: 3,
//       status: 'Off-Track',
//       owner: 'https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80',
//       title: 'Virtual Signs Created',
//       goal: 70,
//       average: 30,
//       goalOrentationRate: 'Outside min and max',
//       goalOrentation: 'T4W & T13W Goal Calculation',
//       description: '',
//       unit: 'Time',
//       total: 763,
//       rollingWeeks: [
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: 4
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 92
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 34
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 92
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 34
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 92
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 34
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 92
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 34
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value: 92
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 34
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 54
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value: 575
//         },

//       ]
//     },
//       {
//       id: 4,
//       status: 'On-Track',
//       owner: 'https://images.unsplash.com/photo-1524504388940-b1c1722653e1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1887&q=80',
//       title: 'Yes and No Row',
//       goal: true,
//       average: 0,
//       goalOrentationRate: 'Equal to Goal',
//       goalOrentation: 'T4W & T13W Goal Calculation',
//       description: '',
//       unit: 'Yes/NO',
//       total: 0,
//       rollingWeeks: [
//         {
//           weekStart: new Date('October 09, 2023 11:13:00'),
//           weekEnd: new Date('October 13, 2023 11:13:00'),
//           value: false
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//                value: true
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//                value: false
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value:  true
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value:  false
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value:  true
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value:  true
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value:  true
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value:  true
//         },
//         {
//           weekStart: new Date('October 02, 2023 11:13:00'),
//           weekEnd: new Date('October 06, 2023 11:13:00'),
//           value:  false
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value:  true
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value:  true
//         },
//         {
//           weekStart: new Date('September 25, 2023 11:13:00'),
//           weekEnd: new Date('September 29, 2023 11:13:00'),
//           value:  true
//         },

//       ]
//     },
// ]

const Data = () => {
  return (
    <>
      <Box ml={2} mt={2}>
        <Typography variant='h6'>Data</Typography>
      </Box>
      <Stack mt={2} direction={{ xs: 'row', sm: 'row' }} justifyContent={'space-around'}>
        <Box sx={{ mr: { md: 5 } }}>
          <Typography color={'gray'} variant='subtitle1'>
            Weekly
          </Typography>
        </Box>

        {/* <Box display={'flex'} justifyContent={'space-evenly'}> */}
        <Typography color={'gray'} variant='subtitle1'>
          Monthly
        </Typography>
        <Typography color={'gray'} variant='subtitle1'>
          Quarterly
        </Typography>
        <Typography color={'gray'} variant='subtitle1'>
          Annual
        </Typography>
        {/* </Box> */}
      </Stack>

      <DataTable />
    </>
  );
};

export default Data;
