import { Print } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';

import { ChangeEvent, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import ConfirmDialog from '../../../common/ConfirmDialog';
import s from '../../styles.module.scss';
import { ReceiptItem } from './types';

type Props = {
  data: ReceiptItem[];
  title: string;
  subtitle: string;
  notes: string;
  qrCode?: string;
  handleClose: () => void;
};

const PrintableTag = ({ data, title, subtitle, notes, qrCode, handleClose }: Props) => {
  const [confirmationDialogInView, setConfirmationDialogInview] = useState<boolean>(false);
  const [bagNumber, setBagNumber] = useState<number>(0);

  const handleOnClose = (res: boolean) => {
    if (res) {
      handleClose();
    }
    setConfirmationDialogInview(false);
  };

  // printing purposes
  const printComponentRef = useRef(null);
  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const updateNumberCount = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const intValue = parseInt(val);
    if (isNaN(intValue)) {
      setBagNumber(0);
    } else {
      setBagNumber(intValue);
    }
  };

  return (
    <>
      <Dialog open={true} onClose={() => setConfirmationDialogInview(true)}>
        <DialogTitle>
          Tag ready to print. <br /> Please Assign a #.
        </DialogTitle>
        <DialogContent>
          <Box display={'grid'} gridTemplateColumns={'75% 25%'} marginBottom={1}>
            <p>Bag #</p>
            <TextField name={'bagNumber'} value={bagNumber} onChange={updateNumberCount} />
          </Box>
          <Box className={s.tagContent}>
            <Typography
              variant='h5'
              borderBottom={'1px solid #000'}
              paddingBottom={1}
              paddingTop={1}
              paddingLeft={3}
              paddingRight={3}
              textAlign={'center'}
              fontWeight={'bold'}
            >
              {title}
            </Typography>
            <Typography
              borderBottom={'1px solid #000'}
              paddingBottom={1}
              paddingTop={1}
              paddingLeft={3}
              paddingRight={3}
              fontSize={14}
              textAlign={'center'}
            >
              {subtitle}
            </Typography>
            {notes && (
              <Typography
                borderBottom={'1px solid #000'}
                paddingBottom={1}
                paddingTop={1}
                paddingLeft={3}
                paddingRight={3}
                fontSize={14}
                textAlign={'center'}
              >
                <b>Notes: </b> {notes}
              </Typography>
            )}
            <List sx={{ padding: 0, margin: 0 }}>
              {data.map((receiptItem: ReceiptItem) => {
                return (
                  <ListItem sx={{ padding: 0, margin: '1vh 0' }} key={receiptItem.item.name}>
                    <Typography paddingLeft={1}>{receiptItem.item.name}</Typography>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          {bagNumber > 0 && (
            <Button
              sx={{
                border: '2px solid rgb(146, 188, 178)',
                ':hover': { color: '#fff', border: 'none', backgroundColor: 'rgb(146, 188, 178)' },
              }}
              onClick={handlePrintReceipt}
            >
              <Print />
            </Button>
          )}
          <Button
            onClick={() => setConfirmationDialogInview(true)}
            sx={{
              color: '#fff',
              backgroundColor: 'crimson',
              ':hover': { color: 'crimson', border: '2px solid crimson' },
            }}
          >
            <Typography>Close</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      {confirmationDialogInView && (
        <ConfirmDialog
          title='Attention'
          message='Please ensure the tag has been printed.'
          handleClick={handleOnClose}
        />
      )}
      {/* USED FOR PRINTING SERVICES */}
      <Box display={'none'} width={'100%'}>
        <Box
          display={'flex'}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
          width={'100%'}
          height={800}
          ref={printComponentRef}
        >
          <Box className={s.tagContent} width={260}>
            <Typography
              borderBottom={'1px solid #000'}
              paddingBottom={1}
              paddingTop={1}
              paddingLeft={1}
              fontSize={16}
              fontWeight={'bold'}
            >
              {title}
            </Typography>
            <Typography
              paddingBottom={1}
              paddingTop={1}
              paddingLeft={1}
              fontSize={16}
              borderBottom={'1px solid #000'}
            >
              {subtitle}
            </Typography>
            {notes && (
              <Typography
                paddingBottom={1}
                paddingTop={1}
                paddingLeft={1}
                fontSize={16}
                borderBottom={'1px solid #000'}
              >
                <b>Notes: </b>
                {notes}
              </Typography>
            )}
            <Box display={'grid'} gridTemplateColumns={'40% 60%'} borderBottom={'1px solid #000'}>
              <Typography
                paddingTop={1}
                paddingBottom={1}
                paddingLeft={1}
                fontSize={14}
                textAlign={'left'}
              >
                Items List
              </Typography>
              <Typography
                paddingTop={1}
                paddingBottom={1}
                paddingRight={1}
                fontSize={14}
                textAlign={'right'}
                fontWeight={'bold'}
              >
                Bag #{bagNumber}
              </Typography>
            </Box>
            <List sx={{ padding: 0, margin: 0 }}>
              {data.map((receiptItem: ReceiptItem) => {
                return (
                  <ListItem sx={{ padding: 0, margin: '1vh 0' }} key={receiptItem.item.name}>
                    <Typography paddingLeft={1}>{receiptItem.item.name}</Typography>
                  </ListItem>
                );
              })}
            </List>
            {qrCode && qrCode?.length > 0 && (
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                borderTop={'1px solid #000'}
              >
                <Typography textAlign={'center'} padding={1}>
                  Scan QR code to add the dry weight
                </Typography>
                <Box padding={1}>
                  <img src={qrCode} alt='qr code receipt' />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PrintableTag;
