import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ status, titles }: { status?: string[]; titles?: string[] }) => {
  const { user } = useAuth();
  const location = useLocation();
  if (!status) {
    // if not logged in, send to login
    return user?.uid ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />;
  }
  // there is a min status requirement but no title requirement
  else if (status && titles == undefined) {
    // checking if the status includes super-admin
    if (status.includes('super-admin') && user.status == 'super-admin') {
      return <Outlet />;
    }
    // checking if status includes 'business-owner'
    else if (
      status.includes('business-owner') &&
      user.status == 'business-owner' &&
      user.title == null
    ) {
      return <Outlet />;
    } else {
      return <Navigate to='/' state={{ from: location }} replace />;
    }
  }
  // min status requirement and title requirement
  else {
    // checking if the status includes super-admin
    if (status.includes('super-admin') && user.status == 'super-admin') {
      return <Outlet />;
    }
    // checking if status includes 'business-owner'
    else if (
      status.includes('business-owner') &&
      user.status == 'business-owner' &&
      user.title == null
    ) {
      return <Outlet />;
    } else {
      if (status.includes(user.status) && titles && titles.includes(user.title)) {
        return <Outlet />;
      } else {
        return <Navigate to='/' state={{ from: location }} replace />;
      }
    }
  }
  // else {
  //   return <Navigate to='/login' state={{ from: location }} replace />;
  // }
  // // if not logged in, send to login
  // return user?.uid ? <Outlet /> : <Navigate to='/login' state={{ from: location }} replace />;
};

export default ProtectedRoute;
