import Dashboard from '../components/fc-os/dashboard/Dashboard';
import { useAuth } from '../context/AuthContext';
import { Employee } from '../types/types';
import { DashboardProvider } from '../context/DashboardContext';

const DashboardPage = () => {
  const { user }: { user: Employee } = useAuth();

  return (
    <>
      <DashboardProvider>
        <Dashboard user={user} />
      </DashboardProvider>
    </>
  );
};

export default DashboardPage;
