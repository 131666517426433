import { Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { ErrorType, LaundryItem } from '../../../../types/types';
import UnknownItemForm from './UnknowItemForm';
import { ReceiptItem } from './types';

const ItemsSelection = ({
  todaysDate,
  trackWetDryWeight,
  moveToNextStep,
  itemsList,
  alreadySelectedItems,
  alreadySelectedTotals,
  sendItemsListToParent,
  sendReceiptTotalsToParent,
}: {
  todaysDate: string;
  trackWetDryWeight: boolean;
  moveToNextStep: () => void;
  itemsList: LaundryItem[];
  alreadySelectedItems: ReceiptItem[] | null;
  alreadySelectedTotals: {
    [key: string]: { total: number; label: string; dryTotal?: number };
  } | null;
  sendItemsListToParent: Dispatch<SetStateAction<ReceiptItem[] | null>>;
  sendReceiptTotalsToParent: Dispatch<
    SetStateAction<{
      [key: string]: { total: number; label: string; dryTotal?: number };
    } | null>
  >;
}) => {
  const [currentSelectedItem, setCurrentSelectedItem] = useState<string>('');
  const [currentSelectedItemIndex, setCurrentSelectedItemIndex] = useState<number>(-1);
  const [currentItemQuantitativeValue, setCurrentItemQuantitativeValue] = useState<number>(0);
  const [itemQuantitativeValueError, setItemQuantitativeValueError] = useState<ErrorType>({
    isError: false,
    message: '',
  });

  // list of items being washed
  const [receiptItemsList, setReceiptsItemsList] = useState<ReceiptItem[]>(
    alreadySelectedItems ? alreadySelectedItems : [],
  );
  const [receiptItemsTotals, setReceiptItemsTotals] = useState<{
    [key: string]: { total: number; label: string; dryTotal?: number };
  } | null>(alreadySelectedTotals ? alreadySelectedTotals : null);
  // unknown item form trigger
  const [unknownItemFormInView, setUnknownItemFormInView] = useState<boolean>(false);

  const resetFormData = () => {
    setCurrentSelectedItem('');
    setCurrentSelectedItemIndex(-1);
    setCurrentItemQuantitativeValue(0);
    setItemQuantitativeValueError({ isError: false, message: '' });
  };

  // Function used by the select drop down
  const handleItemSelectChange = (event: SelectChangeEvent) => {
    if (event.target.value !== 'unknown') {
      setUnknownItemFormInView(false);
      if (itemsList) {
        const index = itemsList?.map((item) => item.name).indexOf(event.target.value);
        setCurrentSelectedItemIndex(index);
      }
      setCurrentSelectedItem(event.target.value as string);
    } else {
      setCurrentSelectedItemIndex(-1);
      setCurrentSelectedItem('');
      setUnknownItemFormInView(true);
    }
  };
  // function used for adding an item to the itemslist
  const handleItemAdd = () => {
    // error checking. quantitativeValue must be greater than 0
    if (currentItemQuantitativeValue > 0) {
      setItemQuantitativeValueError({ isError: false, message: '' });
    } else {
      setItemQuantitativeValueError({ isError: true, message: 'Value must be greater than 0.' });
      return;
    }
    if (itemsList) {
      const tempItem = itemsList[currentSelectedItemIndex];
      const itemAlreadyInList = receiptItemsList.findIndex(
        (item) => item.item.name == tempItem.name,
      );

      if (itemAlreadyInList == -1) {
        if (trackWetDryWeight) {
          // validation passed add to list of items
          setReceiptsItemsList([
            ...receiptItemsList,
            {
              item: tempItem,
              quantity: currentItemQuantitativeValue,
              dryQuantity: 0,
            },
          ]);
        } else {
          // validation passed add to list of items
          setReceiptsItemsList([
            ...receiptItemsList,
            {
              item: tempItem,
              quantity: currentItemQuantitativeValue,
            },
          ]);
        }
      } else {
        const _receiptItemsList = [...receiptItemsList];
        const _tempItem = _receiptItemsList[itemAlreadyInList];
        _tempItem.quantity = _tempItem.quantity + currentItemQuantitativeValue;
        _receiptItemsList[itemAlreadyInList] = _tempItem;
        setReceiptsItemsList(_receiptItemsList);
      }
      // first time adding an item to the total
      if (receiptItemsTotals == null) {
        let _newReceiptItem;
        if (trackWetDryWeight && tempItem.quantitativeType == 'weight') {
          _newReceiptItem = {
            [tempItem.quantitativeType]: {
              total: currentItemQuantitativeValue,
              label: tempItem.quantitativeLabel,
              dryTotal: 0,
            },
          };
        } else {
          _newReceiptItem = {
            [tempItem.quantitativeType]: {
              total: currentItemQuantitativeValue,
              label: tempItem.quantitativeLabel,
            },
          };
        }
        setReceiptItemsTotals(_newReceiptItem);
        resetFormData();
      } // quantitative label already exist. need to update current value
      else if (tempItem.quantitativeType in receiptItemsTotals) {
        const _quantitativeReceiptItems = { ...receiptItemsTotals };
        const newQuantitativeValue =
          receiptItemsTotals[tempItem.quantitativeType].total + currentItemQuantitativeValue;
        _quantitativeReceiptItems[tempItem.quantitativeType].total = newQuantitativeValue;
        setReceiptItemsTotals(_quantitativeReceiptItems);
        resetFormData();
      } // quantitative value does not exist in already existing items object
      else {
        const _receiptItemsList = { ...receiptItemsTotals };
        _receiptItemsList[tempItem.quantitativeType] = {
          total: currentItemQuantitativeValue,
          label: tempItem.quantitativeLabel,
        };
        setReceiptItemsTotals(_receiptItemsList);
        resetFormData();
      }
    }
  };

  // function for removing an item
  const handleRemoveItem = (index: number) => {
    const _receiptItemsList = [...receiptItemsList];
    const removedItem = _receiptItemsList.splice(index, 1)[0];
    // removing from the totals
    const _receiptItemsTotals = { ...receiptItemsTotals };
    const _newWeight =
      _receiptItemsTotals[removedItem.item.quantitativeType].total - removedItem.quantity;

    // remove item from totals
    if (_newWeight == 0) {
      delete _receiptItemsTotals[removedItem.item.quantitativeType];
    } else {
      _receiptItemsTotals[removedItem.item.quantitativeType].total = _newWeight;
    }
    if (Object.keys(_receiptItemsTotals).length === 0) {
      setReceiptItemsTotals(null);
    } else {
      setReceiptItemsTotals(_receiptItemsTotals);
    }
    setReceiptsItemsList(_receiptItemsList);
  };
  // function for sending info to parent
  const handleReviewClick = () => {
    sendItemsListToParent(receiptItemsList);
    sendReceiptTotalsToParent(receiptItemsTotals);
    // updating to the next step
    moveToNextStep();
  };
  // passed to the unknown item form
  const handleUnknownItemAdd = (unknowItem: ReceiptItem) => {
    const tempItem = unknowItem.item;
    const itemAlreadyInList = receiptItemsList.findIndex((item) => item.item.name == tempItem.name);

    if (itemAlreadyInList == -1) {
      if (trackWetDryWeight) {
        // validation passed add to list of items
        setReceiptsItemsList([
          ...receiptItemsList,
          {
            item: tempItem,
            quantity: unknowItem.quantity,
            dryQuantity: 0,
          },
        ]);
      } else {
        // validation passed add to list of items
        setReceiptsItemsList([
          ...receiptItemsList,
          {
            item: tempItem,
            quantity: unknowItem.quantity,
          },
        ]);
      }
    } else {
      const _receiptItemsList = [...receiptItemsList];
      const _tempItem = _receiptItemsList[itemAlreadyInList];
      _tempItem.quantity = _tempItem.quantity + unknowItem.quantity;
      _receiptItemsList[itemAlreadyInList] = _tempItem;
      setReceiptsItemsList(_receiptItemsList);
    }

    // const tempItem = unknowItem.item;
    // first time adding an item to the total
    if (receiptItemsTotals == null) {
      let _newReceiptItem;
      if (trackWetDryWeight && tempItem.quantitativeType == 'weight') {
        _newReceiptItem = {
          [tempItem.quantitativeType]: {
            total: currentItemQuantitativeValue,
            label: tempItem.quantitativeLabel,
            dryTotal: 0,
          },
        };
      } else {
        _newReceiptItem = {
          [tempItem.quantitativeType]: {
            total: unknowItem.quantity,
            label: tempItem.quantitativeLabel,
          },
        };
      }
      setReceiptItemsTotals(_newReceiptItem);
      setUnknownItemFormInView(false);
    } // quantitative label already exist. need to update current value
    else if (tempItem.quantitativeType in receiptItemsTotals) {
      const _quantitativeReceiptItems = { ...receiptItemsTotals };
      const newQuantitativeValue =
        receiptItemsTotals[tempItem.quantitativeType].total + unknowItem.quantity;
      _quantitativeReceiptItems[tempItem.quantitativeType].total = newQuantitativeValue;
      setReceiptItemsTotals(_quantitativeReceiptItems);
      setUnknownItemFormInView(false);
    } // quantitative value does not exist in already existing items object
    else {
      const _receiptItemsList = { ...receiptItemsTotals };
      if (trackWetDryWeight && tempItem.quantitativeType == 'weight') {
        _receiptItemsList[tempItem.quantitativeType] = {
          total: unknowItem.quantity,
          label: tempItem.quantitativeLabel,
          dryTotal: 0,
        };
      } else {
        // _receiptItemsList[tempItem.quantitativeType].total = unknowItem.quantity;
        _receiptItemsList[tempItem.quantitativeType] = {
          total: unknowItem.quantity,
          label: tempItem.quantitativeLabel,
        };
      }
      setReceiptItemsTotals(_receiptItemsList);
      setUnknownItemFormInView(false);
    }
  };

  return (
    <>
      <Typography textAlign={'center'} paddingBottom={2}>
        Receipt Date: <strong>{todaysDate}</strong>
      </Typography>
      <FormControl fullWidth>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={currentSelectedItem}
          label='Item'
          onChange={handleItemSelectChange}
        >
          {itemsList &&
            itemsList.map((item: LaundryItem) => {
              return (
                <MenuItem value={item.name} key={item.name}>
                  {item.name}
                </MenuItem>
              );
            })}
          <MenuItem value={'unknown'}>Unlisted Item</MenuItem>
        </Select>
        {currentSelectedItem && itemsList && (
          <>
            <TextField
              label={`Item ${itemsList[currentSelectedItemIndex].quantitativeType}`}
              name='binWeight'
              margin='dense'
              type='number'
              fullWidth
              value={currentItemQuantitativeValue}
              error={itemQuantitativeValueError.isError}
              helperText={itemQuantitativeValueError.message}
              onChange={(e) => {
                const itemWeight = parseFloat(e.target.value);
                if (isNaN(itemWeight)) setCurrentItemQuantitativeValue(0);
                setCurrentItemQuantitativeValue(itemWeight);
              }}
              // disabled={isAllDisabled}
            />
            <Button variant='contained' onClick={handleItemAdd}>
              Add Item
            </Button>
          </>
        )}
      </FormControl>
      {unknownItemFormInView && <UnknownItemForm addItemToParentList={handleUnknownItemAdd} />}
      {receiptItemsTotals && Object.keys(receiptItemsTotals).length > 0 && (
        <>
          <Box display={'grid'} gridTemplateColumns={'50% 50%'}>
            {Object.keys(receiptItemsTotals).map((receiptItemKey) => {
              return (
                <p key={receiptItemKey}>
                  Total {receiptItemKey}:{' '}
                  <strong>{receiptItemsTotals[receiptItemKey].total}</strong>
                </p>
              );
            })}
          </Box>
          <TableContainer
            component={Paper}
            className='hideScrollBar'
            style={{
              maxHeight: '30vh',
              overflow: 'scroll',
              width: '100%',
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Item Name</TableCell>
                  <TableCell align='center' style={{ fontWeight: 'bold' }}>
                    Quantity
                  </TableCell>
                  <TableCell align='center' style={{ fontWeight: 'bold' }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {receiptItemsList.map((item: ReceiptItem, index: number) => {
                  return (
                    <TableRow
                      key={item.item.name}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component='th' scope='row' style={{ width: '50%' }}>
                        {item.item.name}
                      </TableCell>
                      <TableCell align='center' style={{ width: '30%' }}>
                        {item.quantity} {item.item.quantitativeLabel}
                      </TableCell>
                      <TableCell align='center' style={{ width: '20%' }}>
                        <Button onClick={() => handleRemoveItem(index)}>
                          <Delete color='error' />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant='contained' onClick={handleReviewClick}>
              Review
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

export default ItemsSelection;
