import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import WarningIcon from '@mui/icons-material/Warning';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, MenuList, Typography } from '@mui/material';
import { OSRock, RockStatus } from '../../../../api/fc-os/types/rockTypes';

type Props = {
  rock: OSRock;
  onStatusChange: (id: string, newStatus: RockStatus) => void;
};

const StatusMenu = ({ rock, onStatusChange }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const open = Boolean(anchorEl);

  // NOTE handle click on status change
  const handleThumbClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // NOTE close dropdown clicking off of it
  const handleThumbClose = () => {
    setAnchorEl(null);
  };

  // NOTE close dropdown menu after choice
  const rockStatusChange = (id: string, newStatus: RockStatus) => {
    onStatusChange(id, newStatus);
    handleThumbClose();
  };

  return (
    <Box>
      {rock.status == 'On-Track' ? (
        <ThumbUpIcon
          cursor='pointer'
          htmlColor='green'
          id='thumb-up-button'
          onClick={handleThumbClick}
          aria-expanded={open ? 'true' : undefined}
        ></ThumbUpIcon>
      ) : rock.status == 'Off-Track' ? (
        <WarningIcon
          cursor='pointer'
          htmlColor='red'
          id='thumb-up-button'
          onClick={handleThumbClick}
          aria-expanded={open ? 'true' : undefined}
        ></WarningIcon>
      ) : rock.status == 'Done' ? (
        <CheckCircleIcon
          cursor='pointer'
          htmlColor='rgb(11, 76, 254)'
          id='thumb-up-button'
          onClick={handleThumbClick}
          aria-expanded={open ? 'true' : undefined}
        ></CheckCircleIcon>
      ) : (
        ''
      )}
      <Menu
        id='status-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleThumbClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList>
          <MenuItem>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              onClick={() => rockStatusChange(rock.id, 'Off-Track')}
            >
              <Typography sx={{ mx: 1 }}>Off-Track</Typography>
              <WarningIcon sx={{ mx: 1 }} htmlColor='red' />
            </Box>
          </MenuItem>
          <MenuItem>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              onClick={() => rockStatusChange(rock.id, 'On-Track')}
            >
              <Typography sx={{ mx: 1 }}>On-Track</Typography>
              <ThumbUpIcon htmlColor='green' />
            </Box>
          </MenuItem>
          <MenuItem>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              onClick={() => rockStatusChange(rock.id, 'Done')}
            >
              <Typography sx={{ mx: 1 }}>Done</Typography>
              <CheckCircleIcon htmlColor='rgb(11, 76, 254)' />
            </Box>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export default StatusMenu;
