import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import reportWebVitals from './reportWebVitals';
import './styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(146, 188, 178)',
      light: 'rgb(146, 188, 178, 0.6)',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: '#fff',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: '#000',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '0.5rem',
          boxShadow: '0px 0px 97px -10px rgba(219,219,219,1)',
          border: '1px solid #d2d8e4a5',
          padding: '3.5rem 2rem 2rem 2rem',
          width: '100%',
          position: 'relative',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          position: 'absolute',
          left: '0px',
          top: '0px',
          width: '100%',
          fontSize: 14,
          fontWeight: 'bold',
          borderBottom: '1px solid #d2d8e4',
          padding: '10px 7px 7px 10px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: '#fff',
          border: '2px solid #d2d8e4a5',
          color: '#000',
        },
      },
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <App />
          <Toaster position='bottom-center' />
        </ThemeProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  </React.StrictMode>,
);

reportWebVitals();
