import { Box, Card, CardContent, Typography } from '@mui/material';
import CompanyRocks from './Rock/CompanyRocks';
import PersonalRocks from './Rock/PersonalRocks';
import { RockItem, TodoItem } from '../../types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ModalTypes } from '../../createModal/CreateModal.js';
import { OSRock } from '../../../../api/fc-os/types/rockTypes';
import { getPersonalAndTeamRocks } from '../../../../api/fc-os/rocksAPI';
import { useQuery } from 'react-query';
import { Employee } from '../../../../types/types';
import LoadingSpinner from '../../../common/LoadingSpinner';
type Props = {
  // rockTodos: OSRock[]
  user: Employee;
  setRockTodosList: Dispatch<
    SetStateAction<
      {
        id: number;
        title: string;
        dueDate: Date;
        status: string;
        description: string;
        type: string;
      }[]
    >
  >;
  handleItemEdit: (item: TodoItem | OSRock, type: ModalTypes) => void;
};

const Rocks = ({ user, handleItemEdit, setRockTodosList }: Props) => {
  // loading the data
  const { isError, data, isLoading, dataUpdatedAt } = useQuery(['rocks'], () =>
    getPersonalAndTeamRocks(user),
  );

  const [allRocks, setAllRocks] = useState<OSRock[]>([]);
  const [rocksByOwner, setRocksByOwner] = useState<{ [key: string]: OSRock[] } | null>(null);

  useEffect(() => {
    if (data) {
      // we need to filter through all the Rocks and seperate them between the different users
      console.log(data);
      const allRocksByOwner: { [key: string]: OSRock[] } = {
        company: [],
      };
      // data.forEach((rock) => {
      //   console.log(rock)
      // check to see if Rock is a company
      // if (rock.isPersonal == false) {
      //   allRocksByOwner['company'] = [...allRocksByOwner['company'], rock]
      // }
      // now check who the owners are
      // if more than one owner than we iterate through the owners
      // const rockOwners = rock.owner;
      // if (rockOwners.length > 1) {
      //   for (let i = 0; i < rock.owner.length; i++) {
      //     const rockKey = rockOwners[i].name
      //     if (rockKey in allRocksByOwner) {
      //       allRocksByOwner[rockKey] = [...allRocksByOwner[rockKey], rock]
      //     } else {
      //       allRocksByOwner[rockKey] = [rock]
      //     }
      //   }
      // } else {
      //   console.log(rockOwners)
      //   const rockKey = rockOwners[0].name
      //   if (rockKey in allRocksByOwner) {
      //     allRocksByOwner[rockKey] = [...allRocksByOwner[rockKey], rock]
      //   } else {
      //     allRocksByOwner[rockKey] = [rock]
      //   }
      // }

      // })
      console.log(allRocksByOwner);
      setRocksByOwner(allRocksByOwner);
      setAllRocks(data);
    }
  }, [data]);
  return (
    <>
      <Box ml={2} mt={2}>
        <Typography variant='h6'>Rocks</Typography>
      </Box>
      {isLoading ? (
        <LoadingSpinner />
      ) : allRocks && rocksByOwner ? (
        <>
          {/* SECTION This will house my rock Cards within this Background Card */}
          <Card sx={{ width: 1, padding: 0 }}>
            <CardContent sx={{ width: 1 }}>
              {Object.keys(rocksByOwner).map((owner) => {
                if (owner === 'company') {
                  return (
                    <CompanyRocks
                      key={owner}
                      rockTodos={rocksByOwner[owner]}
                      user={user}
                      setRockTodosList={setAllRocks}
                      handleItemEdit={handleItemEdit}
                    />
                  );
                } else {
                  return (
                    <PersonalRocks
                      key={owner}
                      owner={owner}
                      rockTodos={rocksByOwner[owner]}
                      user={user}
                      setRockTodosList={setAllRocks}
                      handleItemEdit={handleItemEdit}
                    />
                  );
                }
              })}
              {/* SECTION subtitles for Rock Card */}

              {/* SECTION MATT / Miguel Rocks */}
              {/* TODO Map over this for each owner then display their names */}
            </CardContent>
          </Card>
        </>
      ) : (
        <Typography>Failed to load the Rocks.</Typography>
      )}
      {/* <SearchBar page={page} /> */}
    </>
  );
};

export default Rocks;
