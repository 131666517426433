import { HeadlineItem } from '../../../../types';

export const HeadlineData: HeadlineItem[] = [
  {
    id: 1,
    title: 'Headline1',
    status: 'Good',
    description: 'Description1',
    owner: 'Miguel',
    team: 'Fresh Clothes',
    archived: false,
  },
  {
    id: 2,
    title: 'Headline2',
    status: 'Excellent',
    description: 'Description2',
    owner: 'John',
    team: 'Fresh Clothes',
    archived: false,
  },
  {
    id: 3,
    title: 'Headline3',
    status: 'Average',
    description: 'Description3',
    owner: 'Anna',
    team: 'Fresh Clothes',
    archived: false,
  },
  {
    id: 4,
    title: 'Headline4',
    status: 'Poor',
    description: 'Description4',
    owner: 'David',
    team: 'Fresh Clothes',
    archived: false,
  },
  {
    id: 5,
    title: 'Headline5',
    status: 'Excellent',
    description: 'Description5',
    owner: 'Sophia',
    team: 'Fresh Clothes',
    archived: false,
  },
];
