import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDateMonthDay } from '../../../../utils/formatting';
import StatusMenu from './StatusMenu';
import ArrowOrdering from './common/ArrowOrdering';
import { ModalTypes } from '../../createModal/CreateModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteRock,
  editRock,
  getPersonalAndTeamRocks,
  updateRockStatus,
} from '../../../../api/fc-os/rocksAPI';
import { Employee } from '../../../../types/types';
import { OSRock, RockStatus } from '../../../../api/fc-os/types/rockTypes';
import ConfirmDialog from '../../../common/ConfirmDialog';
import toast from 'react-hot-toast';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type Props = {
  user: Employee;
  title: string;
  //   rocksMilestonsList: RockItem[];
  handleEditClick: (item: OSRock, type: ModalTypes) => void;
};

const MilestonesCard = ({ user, title, handleEditClick }: Props) => {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [rocksAndMilestonesList, setRocksAndMilestonesList] = useState<OSRock[]>([]);
  // const [fileredRocksAndMilestonesList, setfilteredRocksAndMilestonesList] = useState<RockItem[]>([...rocksMilestonsList])
  const [status, setStatus] = useState<string>('All');
  const [order, setOrder] = useState<string>('');
  const [confirmationInView, setConfirmationInView] = useState<boolean>(false);
  const [currentRowOrder, setCurrentRowOrder] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRockIndex, setSelectedTodoIndex] = useState<null | number>(null);
  const filters = ['All', 'Off-Track', 'On-Track', 'Done'];

  // loading the data
  const { data, isLoading } = useQuery(['rocks'], () => getPersonalAndTeamRocks(user));
  // function passed to the confirmation dialog component
  // to handle if they accept the deletion
  const handleConfirmationClick = (res: boolean) => {
    if (res && selectedRockIndex !== null) {
      deleteRockOrMilestone(selectedRockIndex);
    }
    setConfirmationInView(false);
  };
  // the function called when a 'Delete' button is clicked for a Rock
  const handleDeleteButtonClick = () => {
    setConfirmationInView(true);
  };
  const handleChange = (todoStatus: string) => {
    setStatus(todoStatus); // Update Status filter state when dropdown value changes
  };

  const deleteRockOrMilestone = async (id: number) => {
    // calling the API delete function first
    // const _rocksAndMilestonesList = [...rocksAndMilestonesList]
    // find the todo and update its status
    // const indexOfRock = _rocksAndMilestonesList.findIndex((rock: OSRock) => {
    //     return rock.id === id
    // })
    // if (indexOfRock > -1) {
    deleteRockMutation.mutate(rocksAndMilestonesList[id]);
    // }
  };

  // This is the function for handling the menu pop for each todo
  const handleThreeDotClick = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedTodoIndex(index);
  };

  // menu code
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedTodoIndex(null);
  };

  // order by row lets us know which row we need to order by
  const handleOrderChange = (newOrder: number, orderByRow: string) => {
    const _order = newOrder === 1 ? 'asc' : newOrder === 2 ? 'desc' : '';
    setOrder(_order);
    setCurrentRowOrder(orderByRow);
    // we need to make a copy since sort arranges IN PLACE
    const _filteredRocksAndMilestonesList: any = [...rocksAndMilestonesList];
    if (orderByRow === 'status') {
      _filteredRocksAndMilestonesList.sort((a: any, b: any) => {
        if (_order === 'asc') {
          return a.status.localeCompare(b.status);
        } else if (_order === 'desc') {
          return b.status.localeCompare(a.status);
        }
      });
      // setfilteredRocksAndMilestonesList(_filteredRocksAndMilestonesList)
    } else if (orderByRow === 'title') {
      _filteredRocksAndMilestonesList.sort((a: any, b: any) => {
        if (_order === 'asc') {
          return a.title.localeCompare(b.title);
        } else if (_order === 'desc') {
          return b.title.localeCompare(a.title);
        }
      });
      // setfilteredRocksAndMilestonesList(_filteredRocksAndMilestonesList)
    } else if (orderByRow === 'due-date') {
      _filteredRocksAndMilestonesList.sort((a: any, b: any) => {
        if (_order === 'asc') {
          return a.dueDate - b.dueDate;
        } else if (_order === 'desc') {
          return b.dueDate - a.dueDate;
        }
      });
      // setfilteredRocksAndMilestonesList(_filteredRocksAndMilestonesList)
    }
  };

  const changeRockOrMilestoneStatus = async (id: string, newStatus: RockStatus) => {
    const _rocksAndMilestonesList = [...rocksAndMilestonesList];
    const indexFound = _rocksAndMilestonesList.findIndex((rockMilestone) => {
      return rockMilestone.id === id;
    });
    if (indexFound > -1) {
      // update the status in the database
      const _rockMilestone = { ..._rocksAndMilestonesList[indexFound] };
      const rockStatusUpdated = await updateRockStatus(user, _rockMilestone, newStatus);
      if (rockStatusUpdated) {
        _rockMilestone.status = newStatus;
        _rocksAndMilestonesList[indexFound] = _rockMilestone;
        setRocksAndMilestonesList(_rocksAndMilestonesList);
      }
    }
  };

  const getTodoTableRow = (rock: OSRock, index: number) => {
    return (
      <TableRow key={rock.id}>
        <TableCell component='th' scope='row'>
          <StatusMenu rock={rock} onStatusChange={changeRockOrMilestoneStatus} />
        </TableCell>
        <TableCell>{rock.title}</TableCell>
        <TableCell>
          <Box display={'flex'} alignItems={'center'} justifyContent={'left'}>
            {rock.dueDate.toDate() < new Date() && (
              <FmdBadIcon sx={{ mr: 1 }} htmlColor='red'></FmdBadIcon>
            )}
            <Typography color={rock.dueDate.toDate() < new Date() ? 'red' : '#000'}>
              {formatDateMonthDay(rock.dueDate.toDate().toString())}
            </Typography>
            <IconButton onClick={(event) => handleThreeDotClick(event, index)}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        </TableCell>
        {/* <TableCell>
                    <Stack direction={'row'}>
                        <Button style={{ padding: 0 }} onClick={() => handleEditClick(rock, 'Rock')}>
                            <EditIcon cursor='pointer' htmlColor={lightGray} />
                        </Button>
                        <Button onClick={() => handleDeleteButtonClick(rock.id)} style={{ padding: 0 }}>
                            <DeleteIcon htmlColor='red' />
                        </Button>
                    </Stack>
                </TableCell> */}
      </TableRow>
    );
  };

  useEffect(() => {
    if (!isLoading && data) {
      // we need to only find the Rocks that belong to the owner
      const _rocks: OSRock[] = [];
      data.forEach((rock) => {
        if (rock.owner.uid === user.uid) {
          _rocks.push(rock);
        }
      });
      setRocksAndMilestonesList(_rocks);
    }
  }, [data]);

  // deletion mutation
  const deleteRockMutation = useMutation({
    mutationFn: (rock: OSRock) => deleteRock(rock),
    onMutate: async (rock: OSRock) => {
      await queryClient.cancelQueries({ queryKey: ['rocks'] });
      const previousRocks = queryClient.getQueryData<OSRock[]>(['rocks']);
      if (previousRocks) {
        const _previousRocks = [...previousRocks];
        const foundTodo = _previousRocks.findIndex((r) => {
          return r.id === rock.id;
        });
        if (foundTodo > -1) {
          const newRocks = _previousRocks.splice(foundTodo, 1);
          queryClient.setQueryData<OSRock[]>(['rocks'], newRocks);
        }
      }
      // Return a context object with the snapshotted value
      return { previousRocks };
    },
    onSuccess: (data, variables, context) => {
      if (context && context.previousRocks) {
        const _previousRocks = [...context.previousRocks];
        const foundTodo = _previousRocks.findIndex((r) => {
          return r.id === data;
        });
        if (foundTodo > -1) {
          _previousRocks.splice(foundTodo, 1);
          queryClient.setQueryData<OSRock[]>(['rocks'], _previousRocks);
          toast.success('Successfully deleted Rock!');
          handleClose();
        }
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, rock, context) => {
      if (context?.previousRocks) {
        queryClient.setQueryData(['rocks'], context.previousRocks);
        toast.error('Failed to change todo status');
      }
    },
  });

  return (
    <>
      <Card
        component={Paper}
        sx={{
          maxHeight: { xs: '60vh', md: '70vh' },
          padding: 0,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <CardContent style={{ height: '100%', width: '100%' }}>
          <Grid container height={'100%'} bgcolor={'#fff'} borderRadius={1}>
            <Grid item xs={12} height={'35%'} pt={2}>
              <Stack
                direction={'row'}
                height={'100%'}
                alignItems={'center'}
                justifyContent={'space-around'}
              >
                <Typography>{title}</Typography>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id='filter-completed-status'>Status</InputLabel>
                  <Select
                    labelId='filter-completed-status'
                    id='completed-status-select'
                    value={status}
                    label='Status'
                    // onChange={selectChange}
                    onChange={(e: SelectChangeEvent<string>) => handleChange(e.target.value)}
                  >
                    {filters.map((filter) => {
                      return (
                        <MenuItem key={filter} value={filter}>
                          {filter}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} height={'65%'}>
              <TableContainer component={Paper} style={{ height: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Stack direction={'row'}>
                          <Typography
                            textAlign={'center'}
                            fontWeight={
                              currentRowOrder === 'status' && order !== '' ? 'bold' : 'initial'
                            }
                          >
                            Status
                          </Typography>
                          <ArrowOrdering
                            setOrder={handleOrderChange}
                            orderByProp={'status'}
                            activeOrderRow={currentRowOrder}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction={'row'}>
                          <Typography
                            textAlign={'center'}
                            fontWeight={
                              currentRowOrder === 'title' && order !== '' ? 'bold' : 'initial'
                            }
                          >
                            Title
                          </Typography>
                          <ArrowOrdering
                            setOrder={handleOrderChange}
                            orderByProp={'title'}
                            activeOrderRow={currentRowOrder}
                          />
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction={'row'}>
                          <Typography
                            textAlign={'center'}
                            noWrap
                            fontWeight={
                              currentRowOrder === 'due-date' && order !== '' ? 'bold' : 'initial'
                            }
                          >
                            Due Date
                          </Typography>
                          <ArrowOrdering
                            setOrder={handleOrderChange}
                            orderByProp={'due-date'}
                            activeOrderRow={currentRowOrder}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rocksAndMilestonesList.length > 0 ? (
                      rocksAndMilestonesList.map((rock, index) => {
                        if (status === 'All') {
                          return getTodoTableRow(rock, index);
                        } else {
                          if (rock.status === status) {
                            return getTodoTableRow(rock, index);
                          }
                        }
                      })
                    ) : (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <Typography>NO ROCKS</Typography>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Menu
        id='actions-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleEditClick(
              selectedRockIndex
                ? rocksAndMilestonesList[selectedRockIndex]
                : rocksAndMilestonesList[0],
              'Rock',
            );
            handleClose();
          }}
        >
          <Grid container>
            <Grid item xs={10}>
              <Typography color={'green'}>Edit</Typography>
            </Grid>
            <Grid item xs={2}>
              <EditIcon cursor='pointer' htmlColor={'green'} />
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={handleDeleteButtonClick}>
          <Grid container>
            <Grid item xs={10}>
              <Typography color={'red'}>Delete</Typography>
            </Grid>
            <Grid item xs={2}>
              <DeleteIcon htmlColor='red' />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
      {confirmationInView && (
        <ConfirmDialog
          title={'Please confirm action'}
          message='Are you sure you want to delete this Rock?'
          handleClick={handleConfirmationClick}
        />
      )}
    </>
  );
};

export default MilestonesCard;
