import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { auth, db } from '../firebase-config';
import { Employee, IAuthContext } from '../types/types';

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);

type AuthContextProviderProps = {
  children: ReactNode;
};

const UndefinedEmployee = {
  uid: undefined,
  active: false,
  createdAt: '',
  email: '',
  endDate: '',
  firstName: '',
  lastLoggedIn: '',
  lastName: '',
  phone: '',
  roles: [''],
  startDate: '',
  status: '',
  storeID: '',
  title: '',
  updatedDate: '',
};

const LIST_OF_STATUS = [
  'super-admin',
  'business-owner',
  'permanent-employee',
  'temporary-employee',
];
export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
  // const [user, setUser] = useState<{ uid: string | undefined }>({ uid: undefined });
  const [user, setUser] = useState<Employee>(UndefinedEmployee);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // called every time the user state changes
    const unsubscribe = onAuthStateChanged(auth, () => {
      if (auth.currentUser?.uid) {
        // NEW CODE WILL FETCH FROM EMPLOYEES TABLE
        const employeeRef = doc(db, 'employees', auth.currentUser.uid);
        getDoc(employeeRef).then((docSnap) => {
          const employeeInfo = docSnap.data();
          // status check. Must have a status and be active
          if (LIST_OF_STATUS.includes(employeeInfo?.status) && employeeInfo?.active == true) {
            setIsLoading(false);
            return setUser({
              uid: auth.currentUser?.uid,
              active: employeeInfo.active,
              createdAt: employeeInfo.createdAt,
              email: employeeInfo.email,
              endDate: employeeInfo.endDate,
              firstName: employeeInfo.firstName,
              lastLoggedIn: employeeInfo.lastLoggedIn,
              lastName: employeeInfo.lastName,
              phone: employeeInfo.phone,
              roles: employeeInfo.roles,
              startDate: employeeInfo.startDate,
              status: employeeInfo.status,
              storeID: employeeInfo.storeID,
              title: employeeInfo.title,
              updatedDate: employeeInfo.updatedDate,
            });
          } else {
            setIsLoading(false);
            return;
          }
        });
        // OLD CODE BELOW - fetches from the user's table
        // const userRef = doc(db, 'users', auth.currentUser.uid);

        // getDoc(userRef).then((docSnap) => {
        //   const userInfo = docSnap.data();

        //   // admin check
        //   if (userInfo?.status === 'admin') {
        //     setIsLoading(false);
        //     return setUser({
        //       uid: auth.currentUser?.uid,
        //     });
        //   } else {
        //     setIsLoading(false);
        //     return;
        //   }
        // });
        // END OF OLD CODE
      } else {
        setIsLoading(false);
        return;
      }
    });
    // remove listener on unmount
    return () => unsubscribe();
  }, []);

  // signs user in with email and password
  const signInWithEmail = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return 'success';
    } catch {
      return 'error';
    }
  };
  // signs out user and removes user from state
  const logout = async () => {
    setUser(UndefinedEmployee);
    await signOut(auth);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        signInWithEmail,
        logout,
      }}
    >
      {!isLoading && children}
    </AuthContext.Provider>
  );
};
