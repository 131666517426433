const validateMinRequirements = (stringToCheck: string, minReq: number) => {
  if (stringToCheck.length < minReq) return false;
  return true;
};

const validateRegex = (stringToCheck: string, regex: RegExp) => {
  const r = new RegExp(regex);
  return r.test(stringToCheck);
};

export { validateMinRequirements, validateRegex };
