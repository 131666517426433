export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

// this will have the list of role that can be done to each collection
// found in the firestore database
export const ROLES: { [key: string]: string[] } = {
  employees: ['add_employee', 'read_employee', 'update_employee', 'delete_employee'],
  users: ['add_user', 'read_user', 'update_user', 'delete_user'],
  stores: ['add_store', 'read_store', 'update_store', 'delete_store'],
  machines: ['add_machine', 'read_machine', 'update_machine', 'delete_machine'],
  binHistory: ['add_bin_history', 'read_bin_history', 'update_bin_history', 'delete_bin_history'],
};

// this is an object that has the details of the default roles for the
// employee titles
// the arrays for the titles gives the index of roles they have
// -1 means they will not have that role
export const EMPLOYEE_ROLES: { [key: string]: string[] } = {
  'super-admin': [
    'add_employee',
    'read_employee',
    'update_employee',
    'delete_employee',
    'add_user',
    'read_user',
    'update_user',
    'delete_user',
    'add_store',
    'read_store',
    'update_store',
    'delete_store',
    'add_machine',
    'read_machine',
    'update_machine',
    'delete_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
    'delete_bin_history',
  ],
  'business-owner': [
    'add_employee',
    'read_employee',
    'update_employee',
    'delete_employee',
    'read_user',
    'update_user',
    'read_store',
    'add_machine',
    'read_machine',
    'update_machine',
    'delete_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
  ],
  manager: [
    'read_employee',
    'update_employee',
    'read_user',
    'update_user',
    'read_store',
    'read_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
  ],
  'assistant-manager': [
    'read_employee',
    'update_employee',
    'read_user',
    'update_user',
    'read_store',
    'read_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
  ],
  driver: [
    'read_user',
    'update_user',
    'read_store',
    'read_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
  ],
  washer: [
    'read_user',
    'read_store',
    'read_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
  ],
};

export const TEMPORARY_EMPLOYEE_ROLES: { [key: string]: string[] } = {
  driver: [
    'read_user',
    'read_store',
    'read_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
  ],
  washer: [
    'read_user',
    'read_store',
    'read_machine',
    'add_bin_history',
    'read_bin_history',
    'update_bin_history',
  ],
};
