import { DialogTitle, Stack, Typography } from '@mui/material';

type Props = {
  fullname: string;
  address: string;
  apt: string | null;
  city: string;
  state: string;
  postalcode: string;
};

const QRModalDialogTitle = ({ fullname, address, apt, city, state, postalcode }: Props) => {
  return (
    <DialogTitle>
      <Stack direction={'column'}>
        <Typography variant='h5' align='center'>
          {fullname}
        </Typography>
        <Typography align='center'>
          {address} {apt} {city}, {state} {postalcode}
        </Typography>
      </Stack>
    </DialogTitle>
  );
};

export default QRModalDialogTitle;
